import { DataSource } from '@angular/cdk/collections';
import { Component, OnInit, Inject } from '@angular/core';
import { MatTableModule, MatTableDataSource } from '@angular/material/table';
import { UserService } from '../services/user.service';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CommunicationService } from '../services/communication.service';
import { Sort } from '@angular/material/sort';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  dataSource: any;
  // displayedColumns: string[];
  isCheckData: boolean;
  broadcastSubs: any;
  userListData: any;
  searchUser: any = "";
  userListDataRes: any;
  edit: any;
  subscription: Subscription;
  displayedColumns: string[] = ['teamsid', 'Last Name', 'First Name', 'Rank', 'email', 'Cell', 'Role', 'Location', 'Language', 'Admin','_id'];
  constructor(private userService: UserService, private communicationServ: CommunicationService, private route: Router, private dialog: MatDialog) {
    this.subscription = this.userService.getReload().subscribe(reload => {
      var profile = reload.text;
      if (profile == 'reload') {
        this.userList();
      }
    });
  }


  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  ngOnInit() {
    // to get accesstoken
    let animation = (timer) => {
      console.log("ngOnInit userList");
      if (this.userService.accessToken) {
        this.userList();
        cancelAnimationFrame(AnimationTimer);
      } else {
        AnimationTimer = window.requestAnimationFrame(animation);
      }
    }
    var AnimationTimer = window.requestAnimationFrame(animation);
  }

  userList() {
    this.userService.getUserData()
      .subscribe(
        (response) => {
          console.log('response received', response)
          var repos = response;
          this.searchUser = "";
          this.userListDataRes = repos;
          this.userListData = this.userListDataRes;
          // this.dataSource = new MatTableDataSource(repos);
          // this.displayedColumns = ['First Name', 'teamsid', 'Role'];
          this.userListData = this.userListDataRes.slice();

        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }
  sortData(sort: Sort) {
    const data = this.userListDataRes.slice();
    if (!sort.active || sort.direction === '') {
      this.userListData = data;
      return;
    }

    this.userListData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      console.log("data", sort.active);
      switch (sort.active) {

        case 'teamsid':
          return compare(a.teamsid, b.teamsid, isAsc);
        case 'Last Name':
          return compare(a['Last Name'], b['Last Name'], isAsc);
        case 'First Name':
          return compare(a['First Name'], b['First Name'], isAsc);
        case 'Rank E':
          return compare(a['Rank E'], b['Rank E'], isAsc);
        case 'email':
          return compare(a.email, b.email, isAsc);
        case 'Cell':
          return compare(a.Cell, b.Cell, isAsc);
        case 'Role':
          return compare(a.Role, b.Role, isAsc);
        case 'Location':
          return compare(a.Location, b.Location, isAsc);
        case 'Language':
          return compare(a.Language, b.Language, isAsc);
        case 'Admin':
          return compare(a.Admin, b.Admin, isAsc);
        default:
          return 0;
      }
    });
  }
  searchUserData() {
    var filterData = [];
    var filterUserKey = ['First Name', 'Last Name', 'Role', 'teamsid', 'email', 'Language', 'Admin', 'Cell']
    if (this.searchUser === '') {
      filterData = null;
      this.userListData = this.userListDataRes;
    } else {
      if (this.userListDataRes.length > 0) {
        for (let i = 0; i < this.userListDataRes.length; i++) {
          if (filterUserKey.length > 0) {
            filterUserKey.forEach((key) => {
              if (typeof this.userListDataRes[i][key] === 'string' && typeof this.searchUser === 'string') {
                if (this.userListDataRes[i][key].toString().toLowerCase().indexOf(this.searchUser.toLowerCase()) > -1) {
                  const found = filterData.some(el => el._id === this.userListDataRes[i]['_id']);
                  if (!found) {
                    filterData.push(this.userListDataRes[i]);
                  }
                }
              }
            });
          }
        }
      }
      this.userListData = filterData;
    }
  }
  searchUserClear() {
    this.searchUser = "";
    this.userListData = this.userListDataRes;
    let filterdData = null;
  }

  tabChanged(event) {
    if (event.index > 0) {
      var activeIndex = event.index;
      this.route.navigate(['/admin']);
      setTimeout(() => {
        this.broadcastInfo({ src: 'admin', event: 'activeTab', data: activeIndex });
      }, 500)

    }
  }
  // displayedColumns: string[] = ['TeamsID', 'Last_Name', 'First_Name', 'Rank', 'Email', 'Mobile', 'Role', 'Location', 'Language', 'Admin', 'Image', 'Edit', 'Delete'];
  // dataSource = new UserDataSource(this.userService);
  deleteuser(user) {
    this.userService.deleteUserData(user).subscribe(data => {
      this.userService.getUserData();
    }, error => {
      console.log(error);
    })
    this.userList();
  }
  editUser(data, id) {
    this.dialog.open(AdminPopup, {
      data: {
        user: {
          id: '',
        },
        edit: {
          edit: data,
          id: id,
        },
      },
    });
  }
  openDialog(): void {
    this.dialog.open(AdminPopup, {
      data: {
        user: {
          id: '',
        },
      },
    });
  }
}

// applyFilter(event: Event) {
//   const filterValue = (event.target as HTMLInputElement).value;
//   this.dataSource.filter = filterValue.trim().toLowerCase();
// }
function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

@Component({
  selector: 'admin-popup',
  templateUrl: 'admin-popup.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminPopup {
  id: string;
  isAddMode: boolean;
  userId: any;
  userFormGroup!: FormGroup;
  CreateUserButton: boolean = true;
  UpdateUserButton: boolean = false;
  roles = ["CO", "OOD", "DPO", "QM", "MSEO", "CSEO", "CCFL", "CCFP", "WFM", "QHM","FLEETWAY"];
  subscription: Subscription;
  Admin = new FormControl('N');
  SDMS = new FormControl('N');
  MARPAC = new FormControl('N');
  MARLANT = new FormControl('N');
  dvt = new FormControl('N');
  radhaz = new FormControl('N');
  Commsc = new FormControl('N');
  commanding = new FormControl('N');
  sdmslibrary = new FormControl('N');
  Officer = new FormControl('N');
  fleetway = new FormControl('N');
  ourship = new FormControl('N');
  yourship = new FormControl('N');
  SCS = new FormControl('N');
  fleets = new FormControl('N');
  ern = new FormControl('N');
  aops = new FormControl('N');
  materiel = new FormControl('N');
  compartments = new FormControl('N');
  scslibrary = new FormControl('N');
  nsc = new FormControl('N');
  stru = new FormControl('N');
  buoyancy = new FormControl('N');
  eng = new FormControl('N');
  seam = new FormControl('N');
  fire = new FormControl('N');
  EER = new FormControl('N');
  navalcomms = new FormControl('N');
  navi = new FormControl('N');
  dang = new FormControl('N');
  good = new FormControl('N');
  damage = new FormControl('N');
  prop = new FormControl('N');
  electrical = new FormControl('N');
  marine = new FormControl('N');
  deck = new FormControl('N');
  hull = new FormControl('N');
  hvac = new FormControl('N');
  aopsdamage = new FormControl('N');
  aopsprop = new FormControl('N');
  aopselectrical = new FormControl('N');
  aopsmarine = new FormControl('N');
  aopsdeck = new FormControl('N');
  aopshull = new FormControl('N');
  aopshvac = new FormControl('N');
  aopscoundselectrical = new FormControl('N');
  aircraftsupport = new FormControl('N');
  navis = new FormControl('N');
  imps = new FormControl('N');
  workshop = new FormControl('N');
  communication = new FormControl('N');
  navysys = new FormControl('N');
  candc = new FormControl('N');
  weapons = new FormControl('N');
  domestic = new FormControl('N');
  materielstru = new FormControl('N');
  materielbuoyancy = new FormControl('N');
  materieleng = new FormControl('N');
  materielseam = new FormControl('N');
  materielfire = new FormControl('N');
  materielEER = new FormControl('N');
  materielcomms = new FormControl('N');
  materielnavi = new FormControl('N');
  materieldang = new FormControl('N');
  materielgood = new FormControl('N');
  SLMS = new FormControl('N');
  idg = new FormControl('N');
  inexxt_campus = new FormControl('N');
  certPath = new FormControl('N');
  roleRes = new FormControl('N');
  know_ur_ship = new FormControl('N');
  trouble = new FormControl('N');
  rhib = new FormControl('N');
  slmsvideos = new FormControl('N');
  diesel_gen = new FormControl('N');
  engine_model = new FormControl('N');
  fluid_Systems = new FormControl('N');
  visitor_scan = new FormControl('N');
  visitor_registration = new FormControl('N');
  visitor_information = new FormControl('N');
  quiz = new FormControl('N');
  Buildingsbulid = new FormControl('N');
  power_gen = new FormControl('N');
  power_dis = new FormControl('N');
  build_op = new FormControl('N');
  main_role = new FormControl('N');
  mast = new FormControl('N');
  bulid = new FormControl('N');
  power_gen_rqs = new FormControl('N');
  power_dis_rqs = new FormControl('N');
  power_dis_ls = new FormControl('N');
  role_bulid = new FormControl('N');
  role_power_gen = new FormControl('N');
  role_power_dis = new FormControl('N');
  know_ur_ship_bulid = new FormControl('N');
  know_ur_ship_compartment = new FormControl('N');
  know_ur_ship_misc = new FormControl('N');
  kys_quiz = new FormControl('N');
  know_ur_ship_kys = new FormControl('N');
  Engineulid = new FormControl('N');
  trouble_power_gen = new FormControl('N');
  mepm = new FormControl('N');
  Fmfcb = new FormControl('N');
  msd = new FormControl('N');
  node_dvt = new FormControl('N');
  kys_halifax_class = new FormControl('N');
  kys_halifax_class_ship = new FormControl('N');
  kys_halifax_class_ship_seawater = new FormControl('N');
  sos_save_our_ship = new FormControl('N');

  constructor(@Inject(MAT_DIALOG_DATA) public data: AdminComponent, public dialog: MatDialog, private _formBuilder: FormBuilder, private userService: UserService, private routes: ActivatedRoute, private _location: Location, private route: Router) {
    var user = data.edit ? data.edit : "";
    var userEdit = user.edit ? user.edit : '';
    if (userEdit == "userEdit") {
      this.CreateUserButton = false;
      this.UpdateUserButton = true;
      this.getUserData(user.id);
      this.userId = user.id;
    }
  }


  ngOnInit() {

    this.userFormGroup = this._formBuilder.group({
      email: ['', Validators.required],
      teamsid: ['', Validators.required],
      'Last Name': ['', Validators.required],
      'First Name': ['', Validators.required],
      'Rank E': [''],
      'Rank F': [''],
      'Org E': [''],
      'Org F': [''],
      Cell: [''],
      Location: [''],
      Language: [''],
      SCS: this.SCS,
      SDMS: this.SDMS,
      SLMS: this.SLMS,
      MARTECH: ['Y'],
      ourship: this.ourship,
      yourship: this.yourship,
      MARLANT: this.MARLANT,
      MARPAC: this.MARPAC,
      Admin: this.Admin,
      Role: ['N'],
      FMF: ['Y'],
      WFM: ['NY'],
      hazard: ['Y'],
      aed: ['Y'],
      'first-aid': ['Y'],
      'spill-kits': ['Y'],
      'evacuation-posters': ['Y'],
      kiosk: ['Y'],
      laydown: ['Y'],
      contractors: ['Y'],
      'portable-generators': ['N'],
      'fork-lifts': ['Y'],
      cranes: ['Y'],
      idg: this.idg,
      inexxt_campus: this.inexxt_campus,
      certPath: this.certPath,
      roleRes: this.roleRes,
      know_ur_ship: this.know_ur_ship,
      trouble: this.trouble,
      assemble_part: ['Y'],
      id_part: ['Y'],
      quiz: this.quiz,
      diesel_gen: this.diesel_gen,
      engine_model: this.engine_model,
      fluid_Systems: this.fluid_Systems,
      visitor_registration: this.visitor_registration,
      visitor_scan : this.visitor_scan,
      visitor_information : this.visitor_information,
      Buildingsbulid: this.Buildingsbulid,
      Halifaxbulid: ['Y'],
      Engineulid: this.Engineulid,
      rhib: this.rhib,
      slmsvideos: this.slmsvideos,
      fleetway: this.fleetway,
      dvt: this.dvt,
      radhaz: this.radhaz,
      Commsc: this.Commsc,
      commanding: this.commanding,
      sdmslibrary: this.sdmslibrary,
      Officer: this.Officer,
      fleets: this.fleets,
      nsc: this.nsc,
      ern: this.ern,
      aops: this.aops,
      materiel: this.materiel,
      compartments: this.compartments,
      scslibrary: this.scslibrary,
      stru: this.stru,
      buoyancy: this.buoyancy,
      eng: this.eng,
      seam: this.seam,
      fire: this.fire,
      EER: this.EER,
      navalcomms: this.navalcomms,
      navi: this.navi,
      dang: this.dang,
      good: this.good,
      damage: this.damage,
      prop: this.prop,
      electrical: this.electrical,
      marine: this.marine,
      deck: this.deck,
      hull: this.hull,
      hvac: this.hvac,
      aopsdamage: this.aopsdamage,
      aopsprop: this.aopsprop,
      aopselectrical: this.aopselectrical,
      aopsmarine: this.aopsmarine,
      aopsdeck: this.aopsdeck,
      aopshull: this.aopshull,
      aopshvac: this.aopshvac,
      aopscoundselectrical: this.aopscoundselectrical,
      aircraftsupport: this.aircraftsupport,
      navis: this.navis,
      imps: this.imps,
      workshop: this.workshop,
      communication: this.communication,
      navysys: this.navysys,
      candc: this.candc,
      weapons: this.weapons,
      domestic: this.domestic,
      materielstru: this.materielstru,
      materielbuoyancy: this.materielbuoyancy,
      materieleng: this.materieleng,
      materielseam: this.materielseam,
      materielfire: this.materielfire,
      materielEER: this.materielEER,
      materielcomms:this.materielcomms,
      // materielnavalcomms: this.materielnavalcomms,
      materielgood: this.materielgood,
      materielnavi: this.materielnavi,
      materieldang: this.materieldang,
      power_gen: this.power_gen,
      bulid: this.bulid,
      power_gen_rqs: this.power_gen_rqs,
      power_dis_rqs: this.power_dis_rqs,
      power_dis_ls: this.power_dis_ls,
      role_bulid: this.role_bulid,
      role_power_gen: this.role_power_gen,
      role_power_dis: this.role_power_dis,
      power_dis: this.power_dis,
      build_op: this.build_op,
      main_role: this.main_role,
      mast: this.mast,
      trouble_power_gen: this.trouble_power_gen,
      know_ur_ship_bulid: this.know_ur_ship_bulid,
      know_ur_ship_compartment: this.know_ur_ship_compartment,
      know_ur_ship_misc: this.know_ur_ship_misc,
      kys_quiz: this.kys_quiz,
      know_ur_ship_kys: this.know_ur_ship_kys,
      mepm: this.mepm,
      Fmfcb: this.Fmfcb,
      msd: this.msd,
      node_dvt: this.node_dvt,
      kys_halifax_class: this.kys_halifax_class,
      kys_halifax_class_ship: this.kys_halifax_class_ship,
      kys_halifax_class_ship_seawater: this.kys_halifax_class_ship_seawater,
      sos_save_our_ship: this.sos_save_our_ship,
    });
  }
  GetRoleAccess() {
    console.log("inside");
    if (this.userFormGroup.value.Role === "CO" || this.userFormGroup.value.Role === "OOD") {
      this.userFormGroup.value.SCS = 'Y';
      this.userFormGroup.value.SDMS = 'Y';
      this.userFormGroup.value.SLMS = 'Y';
      this.userFormGroup.value.MARTECH = 'Y';
      this.userFormGroup.value.ourship = 'Y';
      this.userFormGroup.value.yourship = 'Y';
      this.userFormGroup.value.MARLANT = 'Y';
      this.userFormGroup.value.MARPAC = 'Y';
      this.userFormGroup.value.FMF = 'Y';
      this.userFormGroup.value.WFM = 'Y';
      this.userFormGroup.value.hazard = 'Y';
      this.userFormGroup.value.aed = 'Y';
      this.userFormGroup.value['first-aid'] = 'Y';
      this.userFormGroup.value['spill-kits'] = 'Y';
      this.userFormGroup.value['evacuation-posters'] = 'Y';
      this.userFormGroup.value['kiosk'] = 'Y';
      this.userFormGroup.value['laydown'] = 'Y';
      this.userFormGroup.value['contractors'] = 'Y';
      this.userFormGroup.value['portable-generators'] = 'Y';
      this.userFormGroup.value['visitor_information'] = 'Y';
      this.userFormGroup.value['fork-lifts'] = 'Y';
      this.userFormGroup.value['cranes'] = 'Y';
      this.userFormGroup.value.idg = 'Y';
      this.userFormGroup.value.visitor_information = 'Y';
      this.userFormGroup.value.inexxt_campus = 'Y';
      this.userFormGroup.value.certPath = 'Y';
      this.userFormGroup.value.roleRes = 'Y';
      this.userFormGroup.value.know_ur_ship = 'Y';
      this.userFormGroup.value.trouble = 'Y';
      this.userFormGroup.value.assemble_part = 'Y';
      this.userFormGroup.value.id_part = 'Y';
      this.userFormGroup.value.quiz = 'Y';
      this.userFormGroup.value.diesel_gen = 'Y';
      this.userFormGroup.value.Buildingsbulid = 'Y';
      this.userFormGroup.value.Halifaxbulid = 'Y';
      this.userFormGroup.value.Engineulid = 'Y';
      this.userFormGroup.value.rhib = 'Y';
      this.userFormGroup.value.slmsvideos = 'Y';
      this.userFormGroup.value.fleetway = 'N';
    }
    else if (this.userFormGroup.value.Role === "DPO" || this.userFormGroup.value.Role === "QM") {
      this.userFormGroup.value.SCS = 'Y';
      this.userFormGroup.value.SDMS = 'Y';
      this.userFormGroup.value.SLMS = 'Y';
      this.userFormGroup.value.MARTECH = 'Y';
      this.userFormGroup.value.ourship = 'Y';
      this.userFormGroup.value.yourship = 'Y';
      this.userFormGroup.value.MARLANT = 'Y';
      this.userFormGroup.value.MARPAC = 'Y';
      this.userFormGroup.value.FMF = 'Y';
      this.userFormGroup.value.WFM = 'Y';
      this.userFormGroup.value.hazard = 'Y';
      this.userFormGroup.value.aed = 'Y';
      this.userFormGroup.value['first-aid'] = 'Y';
      this.userFormGroup.value['spill-kits'] = 'N';
      this.userFormGroup.value['evacuation-posters'] = 'N';
      this.userFormGroup.value['kiosk'] = 'N';
      this.userFormGroup.value['laydown'] = 'N';
      this.userFormGroup.value['contractors'] = 'N';
      this.userFormGroup.value['portable-generators'] = 'N';
      this.userFormGroup.value['fork-lifts'] = 'N';
      this.userFormGroup.value['cranes'] = 'N';
      this.userFormGroup.value.idg = 'Y';
      this.userFormGroup.value.visitor_information = 'Y';
      this.userFormGroup.value.inexxt_campus = 'Y';
      this.userFormGroup.value.certPath = 'Y';
      this.userFormGroup.value.roleRes = 'Y';
      this.userFormGroup.value.know_ur_ship = 'Y';
      this.userFormGroup.value.trouble = 'Y'
      this.userFormGroup.value.assemble_part = 'Y';
      this.userFormGroup.value.id_part = 'Y';
      this.userFormGroup.value.quiz = 'Y';
      this.userFormGroup.value.diesel_gen = 'Y';
      this.userFormGroup.value.Buildingsbulid = 'Y';
      this.userFormGroup.value.Halifaxbulid = 'Y';
      this.userFormGroup.value.Engineulid = 'Y';
      this.userFormGroup.value.rhib = 'Y';
      this.userFormGroup.value.slmsvideos = 'Y';
      this.userFormGroup.value.fleetway = 'N';
    }
    else if (this.userFormGroup.value.Role === "FLEETWAY") {
      this.userFormGroup.value.Admin = 'N';
      this.userFormGroup.value.SCS = 'N';
      this.userFormGroup.value.SDMS = 'N';
      this.userFormGroup.value.SLMS = 'Y';
      this.userFormGroup.value.MARTECH = 'N';
      this.userFormGroup.value.ourship = 'N';
      this.userFormGroup.value.yourship = 'N';
      this.userFormGroup.value.MARLANT = 'N';
      this.userFormGroup.value.MARPAC = 'N';
      this.userFormGroup.value.FMF = 'N';
      this.userFormGroup.value.WFM = 'N';
      this.userFormGroup.value.hazard = 'N';
      this.userFormGroup.value.aed = 'N';
      this.userFormGroup.value['first-aid'] = 'N';
      this.userFormGroup.value['spill-kits'] = 'N';
      this.userFormGroup.value['evacuation-posters'] = 'N';
      this.userFormGroup.value['kiosk'] = 'N';
      this.userFormGroup.value['laydown'] = 'N';
      this.userFormGroup.value['contractors'] = 'N';
      this.userFormGroup.value['portable-generators'] = 'N';
      this.userFormGroup.value['fork-lifts'] = 'N';
      this.userFormGroup.value['cranes'] = 'N';
      this.userFormGroup.value.idg = 'N';
      this.userFormGroup.value.visitor_information = 'N';
      this.userFormGroup.value.inexxt_campus = 'N';
      this.userFormGroup.value.certPath = 'N';
      this.userFormGroup.value.roleRes = 'N';
      this.userFormGroup.value.know_ur_ship = 'N';
      this.userFormGroup.value.trouble = 'N'
      this.userFormGroup.value.assemble_part = 'N';
      this.userFormGroup.value.id_part = 'N';
      this.userFormGroup.value.quiz = 'N';
      this.userFormGroup.value.diesel_gen = 'N';
      this.userFormGroup.value.Buildingsbulid = 'N';
      this.userFormGroup.value.Halifaxbulid = 'N';
      this.userFormGroup.value.Engineulid = 'N';
      this.userFormGroup.value.rhib = 'N';
      this.userFormGroup.value.slmsvideos = 'N';
      this.userFormGroup.value.fleetway = 'Y';
    }
    else {
      this.userFormGroup.value.SCS = 'Y';
      this.userFormGroup.value.SDMS = 'Y';
      this.userFormGroup.value.SLMS = 'Y';
      this.userFormGroup.value.MARTECH = 'Y';
      this.userFormGroup.value.ourship = 'Y';
      this.userFormGroup.value.yourship = 'Y';
      this.userFormGroup.value.MARLANT = 'Y';
      this.userFormGroup.value.MARPAC = 'Y';
      this.userFormGroup.value.FMF = 'Y';
      this.userFormGroup.value.WFM = 'Y';
      this.userFormGroup.value.hazard = 'N';
      this.userFormGroup.value.aed = 'N';
      this.userFormGroup.value['first-aid'] = 'N';
      this.userFormGroup.value['spill-kits'] = 'N';
      this.userFormGroup.value['evacuation-posters'] = 'N';
      this.userFormGroup.value['kiosk'] = 'N';
      this.userFormGroup.value['laydown'] = 'N';
      this.userFormGroup.value['contractors'] = 'N';
      this.userFormGroup.value['portable-generators'] = 'N';
      this.userFormGroup.value['fork-lifts'] = 'N';
      this.userFormGroup.value['cranes'] = 'N';
      this.userFormGroup.value.idg = 'Y';
      this.userFormGroup.value.visitor_information = 'Y';
      this.userFormGroup.value.inexxt_campus = 'Y';
      this.userFormGroup.value.certPath = 'Y';
      this.userFormGroup.value.roleRes = 'Y';
      this.userFormGroup.value.know_ur_ship = 'Y';
      this.userFormGroup.value.trouble = 'Y'
      this.userFormGroup.value.assemble_part = 'Y';
      this.userFormGroup.value.id_part = 'Y';
      this.userFormGroup.value.quiz = 'Y';
      this.userFormGroup.value.diesel_gen = 'Y';
      this.userFormGroup.value.Buildingsbulid = 'Y';
      this.userFormGroup.value.Halifaxbulid = 'Y';
      this.userFormGroup.value.Engineulid = 'Y';
      this.userFormGroup.value.rhib = 'Y';
      this.userFormGroup.value.slmsvideos = 'Y';
      this.userFormGroup.value.fleetway = 'N';
    }
    // this.userAccessToggle();
  }
  userAccessToggle(){
    let menuKeys = Object.keys(this.userFormGroup.value);
    if(menuKeys.length>0){
      menuKeys.forEach(ele=>{
        let status = this.userFormGroup.value[ele]=='Y'?true:false;
        let data = {checked:status,source:{id:'main-'+ele}}
        this.toggleChange(data,'ele')
      })
    }
  }

  createUser() {
    this.userService.addUserData(this.userFormGroup.value)
      .subscribe((res) => {
        this.dialog.closeAll();
        this.userService.sendReload("reload");
        var status = res.status
      }, error => {
        console.error('There was an error!');
      })
  }
  updateUser() {
    console.log("updateUser",this.userFormGroup.value);
    this.userService.updateUserData(this.userId, this.userFormGroup.value)
      .subscribe((res) => {
        console.log("updateUserres",res);
        this.dialog.closeAll();
        this.userService.sendReload("reload");
      }, error => {
        console.error('There was an error!');
      })
  }
  toogleSubList(e,element,menuKeys){
    let childSubElem = document.querySelector('#childSub-'+element['id']);
    if(childSubElem!=null){
      var childSubList:any = Array.prototype.slice.call(childSubElem.childNodes);
      if(childSubList.length>0){
        // childSubList
        childSubList.forEach(element => {
          if(element.id){
              menuKeys.forEach(ele=>{
                let menu = ele.split(" ").length==1?ele.toLowerCase():'';
                console.log("menu",element['id']);
                let childMenu = element['id'].split('-')[2]
                if(menu==childMenu.toLowerCase()){
                  console.log('child',ele,menu,childMenu);
                  this.userFormGroup.controls[ele].setValue(e.checked ? 'Y' : 'N');
                }
              })
          }
        })
      }
    }
  }
  toggleChange(e, d) {
    console.log(e,d);
    console.log(document.querySelector('#child-'+e.source.id))
    let childElem = document.querySelector('#child-'+e.source.id);
    if(childElem!=null){
      this.userFormGroup.controls[d].setValue(e.checked ? 'Y' : 'N')
      var childList:any = Array.prototype.slice.call(childElem.childNodes);
   
      if(childList.length>0){
        childList.forEach(element => {
          if(element.id){
            let menuKeys = Object.keys(this.userFormGroup.value);
            console.log("menuKeys",menuKeys);
            if(menuKeys.length>0){
              menuKeys.forEach(ele=>{
                var menu = ele.split(" ").length==1?ele.toLowerCase():'';
                console.log("menu",element['id']);
                let childMenu = element['id'].split('-')[2]
                if(menu==childMenu.toLowerCase()){
                  console.log('child',ele,menu,childMenu);
                  this.userFormGroup.controls[ele].setValue(e.checked ? 'Y' : 'N');
                  if(ele=='visitor_information'|| ele == "idg" || ele == "inexxt_campus" || ele == "certPath" || ele == "roleRes" || ele == "roleRes" || ele =="trouble" || ele == "nsc" || ele == "ern" || ele =="aops" || ele == "materiel"){
                    this.toogleSubList(e,element,menuKeys);
                  }
                
                }
    
              })
            }
          }
        });
        
  
      }
      if (this.userFormGroup.value.nsc == "N") {
        this.navalShip = false;
      }
      if (this.userFormGroup.value.ern == "N") {
        this.ernFamily = false;
      }
      if (this.userFormGroup.value.aops == "N") {
        this.ernFamilyAops = false;
      }
      if (this.userFormGroup.value.materiel == "N") {
        this.materielNavalShip = false;
      }
      if (this.userFormGroup.value.idg == "N") {
        this.generator = false;
      }
      if (this.userFormGroup.value.visitor_information == "N") {
        this.visitorMenu = false;
      }
      if (this.userFormGroup.value.inexxt_campus == "N") {
        this.campus = false;
      }
      if (this.userFormGroup.value.certPath == "N") {
        this.certification = false;
      }
      if (this.userFormGroup.value.roleRes == "N") {
        this.responsibilities = false;
      }
      if (this.userFormGroup.value.know_ur_ship == "N") {
        this.knowurship = false;
      }
      if (this.userFormGroup.value.trouble == "N") {
        this.troubleshooting = false;
      }
    }else{
         if (d) {
      this.userFormGroup.controls[d].setValue(e.checked ? 'Y' : 'N');
      if (this.userFormGroup.value.nsc == "N") {
        this.navalShip = false;
      }
      if (this.userFormGroup.value.ern == "N") {
        this.ernFamily = false;
      }
      if (this.userFormGroup.value.aops == "N") {
        this.ernFamilyAops = false;
      }
      if (this.userFormGroup.value.materiel == "N") {
        this.materielNavalShip = false;
      }
      if (this.userFormGroup.value.idg == "N") {
        this.generator = false;
      }
      if (this.userFormGroup.value.visitor_information == "N") {
        this.visitorMenu = false;
      }
      if (this.userFormGroup.value.inexxt_campus == "N") {
        this.campus = false;
      }
      if (this.userFormGroup.value.certPath == "N") {
        this.certification = false;
      }
      if (this.userFormGroup.value.roleRes == "N") {
        this.responsibilities = false;
      }
      if (this.userFormGroup.value.know_ur_ship == "N") {
        this.knowurship = false;
      }
      if (this.userFormGroup.value.trouble == "N") {
        this.troubleshooting = false;
      }
    }
    }

    // if
    // if (d) {
    //   this.userFormGroup.controls[d].setValue(e.checked ? 'Y' : 'N');
    //   if (this.userFormGroup.value.nsc == "N") {
    //     this.navalShip = false;
    //   }
    //   if (this.userFormGroup.value.ern == "N") {
    //     this.ernFamily = false;
    //   }
    //   if (this.userFormGroup.value.aops == "N") {
    //     this.ernFamilyAops = false;
    //   }
    //   if (this.userFormGroup.value.materiel == "N") {
    //     this.materielNavalShip = false;
    //   }
    //   if (this.userFormGroup.value.idg == "N") {
    //     this.generator = false;
    //   }
    //   if (this.userFormGroup.value.visitor_information == "N") {
    //     this.visitorMenu = false;
    //   }
    //   if (this.userFormGroup.value.inexxt_campus == "N") {
    //     this.campus = false;
    //   }
    //   if (this.userFormGroup.value.certPath == "N") {
    //     this.certification = false;
    //   }
    //   if (this.userFormGroup.value.roleRes == "N") {
    //     this.responsibilities = false;
    //   }
    //   if (this.userFormGroup.value.know_ur_ship == "N") {
    //     this.knowurship = false;
    //   }
    //   if (this.userFormGroup.value.trouble == "N") {
    //     this.troubleshooting = false;
    //   }
    // }
  }
  getUserData(id) {
    this.userService.getUserDataById(id)
      .subscribe(
        (response) => {
          var repos = response;
          var userData = repos[0]
          console.log("getUserData", userData)
          this.userFormGroup.patchValue(userData);
          console.log("test1", this.userFormGroup)
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }
  cancelForm() {
    // this._location.back();
    this.dialog.closeAll();
  }
  navalShip: boolean = false;
  ernFamily: boolean = false;
  ernFamilyAops: boolean = false;
  materielNavalShip: boolean = false;
  generator: boolean = false;
  campus: boolean = false;
  certification: boolean = false;
  responsibilities: boolean = false;
  knowurship: boolean = false;
  troubleshooting: boolean = false;
  videos: boolean = false;
  visitorMenu = false;
  toggleActive: any;
  toggleChildMenu(e) {
    if (e == 'navalShip') {
      this.toggleActive = 'navalShip';
      this.navalShip = !this.navalShip;
      this.ernFamily = false;
      this.ernFamilyAops = false;
      this.materielNavalShip = false;
      this.generator = false;
      this.campus = false;
      this.certification = false;
      this.responsibilities = false;
      this.knowurship = false;
      this.troubleshooting = false;
      this.visitorMenu = false
    }
    if (e == 'ernFamily') {
      this.toggleActive = 'ernFamily';
      this.navalShip = false;
      this.ernFamily = !this.ernFamily;
      this.ernFamilyAops = false;
      this.materielNavalShip = false;
      this.generator = false;
      this.campus = false;
      this.certification = false;
      this.responsibilities = false;
      this.knowurship = false;
      this.troubleshooting = false;
      this.visitorMenu = false
    }
    if (e == 'ernFamilyAops') {
      this.toggleActive = 'ernFamilyAops';
      this.navalShip = false;
      this.ernFamily = false;
      this.ernFamilyAops = !this.ernFamilyAops;
      this.materielNavalShip = false;
      this.generator = false;
      this.campus = false;
      this.certification = false;
      this.responsibilities = false;
      this.knowurship = false;
      this.troubleshooting = false;
      this.visitorMenu = false;
    }
    if (e == 'materielNavalShip') {
      this.toggleActive = 'materielNavalShip';
      this.navalShip = false;
      this.ernFamily = false;
      this.ernFamilyAops = false;
      this.materielNavalShip = !this.materielNavalShip;
      this.generator = false;
      this.campus = false;
      this.certification = false;
      this.responsibilities = false;
      this.knowurship = false;
      this.troubleshooting = false;
      this.visitorMenu = false;
    }
    if (e == 'generator') {
      this.toggleActive = 'generator';
      this.navalShip = false;
      this.ernFamily = false;
      this.ernFamilyAops = false;
      this.materielNavalShip = false;
      this.generator = !this.generator;
      this.campus = false;
      this.certification = false;
      this.responsibilities = false;
      this.knowurship = false;
      this.troubleshooting = false;
      this.visitorMenu = false;
    }
    if (e == 'campus') {
      this.toggleActive = 'campus';
      this.navalShip = false;
      this.ernFamily = false;
      this.ernFamilyAops = false;
      this.materielNavalShip = false;
      this.generator = false;
      this.campus = !this.campus;
      this.certification = false;
      this.responsibilities = false;
      this.knowurship = false;
      this.troubleshooting = false;
      this.visitorMenu = false;
    }
    if (e == 'certification') {
      this.toggleActive = 'certification';
      this.navalShip = false;
      this.ernFamily = false;
      this.ernFamilyAops = false;
      this.materielNavalShip = false
      this.generator = false;
      this.campus = false;
      this.certification = !this.certification;
      this.responsibilities = false;
      this.knowurship = false;
      this.troubleshooting = false;
      this.visitorMenu = false;
    }
    if (e == 'responsibilities') {
      this.toggleActive = 'responsibilities';
      this.navalShip = false;
      this.ernFamily = false;
      this.ernFamilyAops = false;
      this.materielNavalShip = false;
      this.generator = false;
      this.campus = false;
      this.certification = false;
      this.responsibilities = !this.responsibilities;
      this.knowurship = false;
      this.troubleshooting = false;
      this.visitorMenu = false;
    }
    if (e == 'knowurship') {
      this.toggleActive = 'knowurship';
      this.navalShip = false;
      this.ernFamily = false;
      this.ernFamilyAops = false;
      this.materielNavalShip = false;
      this.generator = false;
      this.campus = false;
      this.certification = false;
      this.responsibilities = false;
      this.knowurship = !this.knowurship;
      this.troubleshooting = false;
      this.visitorMenu = false;
    }
    if (e == 'troubleshooting') {
      this.toggleActive = 'troubleshooting';
      this.navalShip = false;
      this.ernFamily = false;
      this.ernFamilyAops = false;
      this.materielNavalShip = false;
      this.generator = false;
      this.campus = false;
      this.certification = false;
      this.responsibilities = false;
      this.knowurship = false;
      this.troubleshooting = !this.troubleshooting;
      this.visitorMenu = false;
    }
    if (e == 'visitor_information') {
      this.toggleActive = 'visitor_information';
      this.navalShip = false;
      this.ernFamily = false;
      this.ernFamilyAops = false;
      this.materielNavalShip = false;
      this.generator = false;
      this.campus = false;
      this.certification = false;
      this.responsibilities = false;
      this.knowurship = false;
      this.troubleshooting = false;
      this.visitorMenu = !this.visitorMenu;
    }
  }
}

