import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ChitService } from '../services/chit.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Location } from '@angular/common';
import { callbackify } from 'util';
import { MatSelect } from '@angular/material';
import { CommunicationService } from '../services/communication.service';

@Component({
  selector: 'app-chits',
  templateUrl: './chits.component.html',
  styleUrls: ['./chits.component.scss']
})
export class ChitsComponent implements OnInit {
  isLinear = true;
  chitId: any;
  userId: any;
  subscription: Subscription;
  supervisorListData: any;
  oodListData: any;
  vesselListData: any;
  tagoutListData: any;
  user: any;
  supervisor: any;
  edit: any;
  create: any;
  selectedIndexBinding: 1;
  broadcastSubs: any;
  constructor(public dialog: MatDialog, private _formBuilder: FormBuilder, private communicationServ: CommunicationService, private chitService: ChitService, private route: Router) {

    this.subscription = this.chitService.getProfileData().subscribe(message => {
      var profile = message.text;
      // if (profile._id == profile._id) {
      //   this.userId = profile._id;
      // }
      if (profile == 'reload') {
        this.supervisorList();
        this.oodList();
        this.vesselList();
        this.tagoutList();
      } else {
        this.userId = profile._id;
      }

      //  console.log("Profile data list", this.userId)
    });
    this.subscription = this.chitService.getReload().subscribe(reload => {
      var profile = reload.text;
      if (profile == 'reload') {
        this.supervisorList();
        this.oodList();
        this.vesselList();
        this.tagoutList();
      }
    });

  }
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }
  ngOnInit() {
    // to get accesstoken
    let animation = (timer) => {
      // console.log("ngOnInit userList");
      if (this.chitService.accessToken) {
        this.supervisorList();
        this.oodList();
        this.vesselList();
        this.tagoutList();
        cancelAnimationFrame(AnimationTimer);
      } else {
        AnimationTimer = window.requestAnimationFrame(animation);
      }
    }
    var AnimationTimer = window.requestAnimationFrame(animation);

    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "admin") {
          if (data.event === 'activeTab') {
            this.selectedIndexBinding = data['data'];
          }
        }
      })
    this.selectedIndexBinding = this.selectedIndexBinding;
  }

  tabChanged(event) {
    if (event.index == 0) {
      this.route.navigate(['/user']);
    } else {
      this.supervisorList();
      this.oodList();
      this.vesselList();
      this.tagoutList();
    }
  }

  // Supervisor

  supervisorList() {
    this.chitService.getSupervisorData()
      .subscribe(
        (response) => {
          // console.log('response received')
          var repos = response;
          this.supervisorListData = repos;
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }
  supervisorDelete(id) {
    this.chitService.deleteSupervisor(id)
      .subscribe(
        (response) => {
          var repos = response;
          this.supervisorList();
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }

  // OOD
  oodList() {
    this.chitService.getOodData()
      .subscribe(
        (response) => {
          if (response.length > 0) {
            this.oodListData = response.reverse();
            // console.log('response received', this.oodListData)

          } else {
            this.oodListData = [];
            // console.log('response received', this.oodListData)

          }
          // console.log('response received', response)
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }
  oodDelete(id) {
    this.chitService.deleteOod(id)
      .subscribe(
        (response) => {
          var repos = response;
          this.oodList();
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }
  // Vessel

  // OOD

  vesselList() {
    this.chitService.getVesselData()
      .subscribe(
        (response) => {
          var repos = response;
          this.vesselListData = repos;
          // console.log('response received', repos)
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }

  vesselDelete(id) {
    this.chitService.deleteVessel(id)
      .subscribe(
        (response) => {
          var repos = response;
          this.vesselList();
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }
  // tagoutList

  tagoutList() {
    this.chitService.getTagoutData()
      .subscribe(
        (response) => {
          var repos = response;
          this.tagoutListData = repos;
          // console.log('response received', repos)
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }
  tagoutDelete(id) {
    this.chitService.deleteTagout(id)
      .subscribe(
        (response) => {
          var repos = response;
          this.tagoutList();
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }
  supervisorEdit(data, id) {
    this.dialog.open(ChitPopup, {
      data: {
        user: {
          id: this.userId,
        },
        edit: {
          edit: data,
          id: id,
        },
        create: {
          create: '',
        },
      },
    });
  }
  openDialog(data): void {
    this.dialog.open(ChitPopup, {
      data: {
        user: {
          id: this.userId,
        },
        supervisor: {
          edit: '',
        },
        ood: {
          edit: '',
        },
        vessel: {
          edit: '',
        },
        create: {
          create: data,
        },
      },
    });
  }


}

@Component({
  selector: 'chit-popup',
  templateUrl: 'chit-popup.html',
  styleUrls: ['./chits.component.scss']
})
export class ChitPopup {
  @ViewChild('vessel', { static: false }) vesselField: MatSelect;
  chitId: any;
  userId: any;
  userListData: any;
  supervisorFormGroup!: FormGroup;
  oodScheduleFormGroup!: FormGroup;
  vesselMasterFormGroup!: FormGroup;
  tagoutMasterFormGroup!: FormGroup;
  tagoutRolesFormGroup!: FormGroup;

  supervisorForm: boolean = false;
  oodForm: boolean = false;
  vesselForm: boolean = false;
  lockoutForm: boolean = false;
  rolesForm: boolean = false;

  CreatesupervisorButton: boolean = true;
  UpdatesupervisorButton: boolean = false;
  CreateOodButton: boolean = true;
  UpdateOodButton: boolean = false;
  CreateVesselButton: boolean = true;
  UpdateVesselButton: boolean = false;
  CreateTagoutButton: boolean = true;
  UpdateTagoutButton: boolean = false;
  createRolesButton: boolean = true;
  updateRolesButton: boolean = false;


  supervisorInfo: any;
  onSiteContactInfo: any;
  // locationUrl: 'https://navysdms.azurewebsites.net/json';
  locationUrl: 'http://localhost:3000/json';
  oodContactInfo: any;
  startTime = '08:00';
  endTime = '20:00';

  subscription: Subscription;
  // chitMasterData: any;
  supervisor: any = [
    { 'id': '001', 'supName': 'Hunter', 'supRank': '001', 'supTelephone': '6139952534' },
    { 'id': '002', 'supName': 'Jackson', 'supRank': '002', 'supTelephone': '6139952534' },
    { 'id': '003', 'supName': 'Noah', 'supRank': '003', 'supTelephone': '6139952534' },
    { 'id': '004', 'supName': 'Liam', 'supRank': '004', 'supTelephone': '6139952534' },
    { 'id': '005', 'supName': 'Benjamin', 'supRank': '005', 'supTelephone': '6139952534' },
    { 'id': '006', 'supName': 'Oliver', 'supRank': '006', 'supTelephone': '6139952534' },
    { 'id': '007', 'supName': 'Ethan', 'supRank': '007', 'supTelephone': '6139952534' }
  ];
  port: any = [
    { 'port': 'CFB Esquimalt', 'value': 'CFB Esquimalt' },
    { 'port': 'CFB Halifax', 'value': 'CFB Halifax' },
  ]
  dockyardeDetails = this.port.port;
  VesselName: any = []
  oodNameDetails: any;
  supervisorNameDetails: any;
  onSiteNameDetails: any;
  // VesselName: any = ['Toronto', 'Waterloo', 'Welland', 'London', 'North York', 'Oakville', 'Nunavut', 'Nova Scotia', 'New Brunswick', 'Manitoba', 'Barkerville', 'Jasper']
  schedule: any = ['OOD', 'Duty Tech', 'URFSO']
  scheduleDuty: any = ['initiate', 'guarantee', 'authorize', 'done'];

  Roles:any = {
     'initiate': ['Tester', 'Maintainer'],
     'guarantee': ['MSEO/CSEO', 'OOD/DTech'],
     'authorize': ['Maintainer', 'Dept Rep', 'MSEO/CSEO', 'OOD/DTech', 'Lockout/Tagout(Coordinator)'],
     'done': ['Qualified Person']    
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: ChitsComponent, public dialog: MatDialog, private _formBuilder: FormBuilder, private chitService: ChitService, private routes: ActivatedRoute, private _location: Location, private route: Router) {
    // this.subscription = this.chitService.getProfileData().subscribe(message => {
    //   var profile = message.text;
    //   this.userId = profile._id;
    //   //  console.log("Profile data list", this.userId)
    // });
    // console.log("Dtat", data);
    var user = data.user
    this.userId = user.id;
    var supervisorData = data.create
    var supervisorCreate = supervisorData.create
    var supervisor = data.edit ? data.edit : "";
    var supervisorEdit = supervisor.edit ? supervisor.edit : '';
    // console.log("Dtat", supervisorEdit);
    if (supervisorCreate == "supervisor") {
      this.supervisorForm = true;
      this.CreatesupervisorButton = true;
      this.UpdatesupervisorButton = false;
    }
    if (supervisorCreate == "ood") {
      this.oodForm = true;
      this.CreateOodButton = true;
      this.UpdateOodButton = false;
    }
    if (supervisorCreate == "vessel") {
      this.vesselForm = true;
      this.CreateVesselButton = true;
      this.UpdateVesselButton = false;
    }
    if (supervisorCreate == "tagout") {
      this.lockoutForm = true;
      this.CreateTagoutButton = true;
      this.UpdateTagoutButton = false;
    }
    if (supervisorEdit == "supervisor") {
      this.supervisorForm = true;
      this.CreatesupervisorButton = false;
      this.UpdatesupervisorButton = true;
      this.getSupervisorData(supervisor.id);
      this.chitId = supervisor.id;
    }
    if (supervisorEdit == "ood") {
      this.oodForm = true;
      this.CreateOodButton = false;
      this.UpdateOodButton = true;
      this.getOodData(supervisor.id);
      this.chitId = supervisor.id;
    }
    if (supervisorEdit == "vessel") {
      this.vesselForm = true;
      this.CreateVesselButton = false;
      this.UpdateVesselButton = true;
      this.getVesselData(supervisor.id);
      this.chitId = supervisor.id;
    }
    if (supervisorEdit == "tagout") {
      this.lockoutForm = true;
      this.CreateTagoutButton = false;
      this.UpdateTagoutButton = true;
      this.getTagoutData(supervisor.id);
      this.getRolesData(supervisor.id);
      this.chitId = supervisor.id;
      this.selectedTagoutID = supervisor.id;
    }
  }


  ngOnInit() {
    // to get accesstoken
    let animation = (timer) => {
      // console.log("ngOnInit userList");
      if (this.chitService.accessToken) {
        this.userList();
        this.getShipConfig();
        cancelAnimationFrame(AnimationTimer);
      } else {
        AnimationTimer = window.requestAnimationFrame(animation);
      }
    }
    var AnimationTimer = window.requestAnimationFrame(animation);

    this.supervisorFormGroup = this._formBuilder.group({
      user_id: [''],
      supervisor_name: ['', Validators.required],
      vessel: ['', Validators.required],
      onSiteContact: ['', Validators.required],
      supervisor_name_email: [''],
      onSiteContactEmail: [''],
    });
    this.oodScheduleFormGroup = this._formBuilder.group({
      ood_name: ['', Validators.required],
      vessel: ['', Validators.required],
      dockyard: ['CFB Esquimalt', Validators.required],
      shedule: ['', Validators.required],
      start_date: [((new Date()).toISOString().substring(0, 10)), Validators.required],
      start_time: ['', Validators.required],
      end_time: ['', Validators.required],
      supervisor_name: ['', Validators.required],
      onSiteContact: ['', Validators.required],
      supervisor_email: [''],
      onSiteContactEmail: [''],
    });
    this.vesselMasterFormGroup = this._formBuilder.group({
      vessel_id: ['', Validators.required],
      vessel_name: ['', Validators.required],
      port: ['', Validators.required],
    });
    this.tagoutMasterFormGroup = this._formBuilder.group({
      name: ['', Validators.required],
      vessel: ['', Validators.required]     
    });
    this.tagoutRolesFormGroup = this._formBuilder.group({
      tagout_id: [this.selectedTagoutID],
      scheduleDuty: ['', Validators.required],    
      role: ['', Validators.required],
    });
  }

  // supervisor
  Createsupervisor() {
    if (this.chitId) {
      // console.log("Update Form Data", this.supervisorFormGroup.value);
      let data = {
        onSiteContact: this.onSiteContactInfo['First Name'],
        onSiteContactEmail: this.onSiteContactInfo.email,
        supervisor_name: this.supervisorInfo['First Name'],
        supervisor_name_email: this.supervisorInfo.email,
        user_id: this.supervisorFormGroup.get('user_id').value,
        vessel: this.supervisorFormGroup.get('vessel').value,
      }
      this.chitService.updateSupervisor(this.chitId, data)
        .subscribe((res) => {
          // console.log("update supervisor ", res);
          // this.route.navigate(['/chits']);
          this.dialog.closeAll();
          this.chitService.sendReload("reload");
        }, error => {
          console.error('There was an error!');
        })
    } else {
      // console.log("Create Form Data", this.supervisorFormGroup.value);
      let data = {
        onSiteContact: this.onSiteContactInfo['First Name'],
        onSiteContactEmail: this.onSiteContactInfo.email,
        supervisor_name: this.supervisorInfo['First Name'],
        supervisor_name_email: this.supervisorInfo.email,
        user_id: this.supervisorFormGroup.get('user_id').value,
        vessel: this.supervisorFormGroup.get('vessel').value,
      }
      // console.log("Create Form Data", data);
      this.chitService.addSupervisor(data)
        .subscribe((res) => {
          // console.log("supervisor", res)
          this.chitId = res.insertedId;
          // this.route.navigate(['/chits']);
          this.dialog.closeAll();
          this.chitService.sendReload("reload");
          var status = res.status
        }, error => {
          console.error('There was an error!');
        })
    }
  }
  getSupervisorData(id) {
    this.chitService.getSupervisorDataList(id)
      .subscribe(
        (response) => {
          var repos = response;
          // console.log('getSupervisorData', repos)
          var supervisor = repos[0]
          this.supervisorFormGroup.patchValue(supervisor);
          this.supervisorNameDetails = supervisor.supervisor_name_email ? supervisor.supervisor_name_email : ""
          this.onSiteNameDetails = supervisor.onSiteContactEmail ? supervisor.onSiteContactEmail : ""
          // this.supervisorFormGroup.setValue({
          //   azureIdCtrl: (this.chitDataList.azureIdCtrl ? this.chitDataList.azureIdCtrl : ""),
          //   ATSEA: (this.chitQHM ? this.chitQHM.ATSEA : ""),
          // });
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }
  Updatesupervisor() {
    let data = {
      onSiteContact: this.supervisorFormGroup.get('onSiteContact').value,
      onSiteContactEmail: this.supervisorFormGroup.get('onSiteContactEmail').value,
      supervisor_name: this.supervisorFormGroup.get('supervisor_name_email').value,
      supervisor_name_email: this.supervisorFormGroup.get('supervisor_name_email').value,
      user_id: this.supervisorFormGroup.get('user_id').value,
      vessel: this.supervisorFormGroup.get('vessel').value,
    }
    // console.log("sa", data)
    // this.chitService.updateSupervisor(this.chitId, data)
    //   .subscribe((res) => {
    //     console.log("update supervisor ", res);
    //     this.route.navigate(['/chits']);
    //     this.dialog.closeAll();
    //     this.chitService.sendReload("reload");
    //   }, error => {
    //     console.error('There was an error!');
    //   })
  }

  getDockyardShipData(port, data, cbck) {
    let shipMetaData = {};
    port = port.split(" ").join("").toLowerCase();
    this.chitService.pullJsonData('json', { port: port, filename: 'shipObjects' }).then((res) => {
      if (res['result'] != 'failed') {
        shipMetaData = res;
        if (shipMetaData['ships'].length > 0) {
          const shipFound = shipMetaData['ships'].some(ship => ship['ship'] === data['vessel'] && (new Date(data['start_date']) >= new Date(ship['startdate']) && new Date(data['start_date']) <= new Date(ship['enddate'])))
          if (shipFound) {
            cbck(true);
          } else {
            cbck(false);
          }
        } else {
          // this.openPublishDialog('metadata not found !!');
          cbck(false);
        }
      } else {
        // this.openPublishDialog('metadata not found !!');
        cbck(false);
      }
    }).catch((error) => {
      console.log(error);
      cbck(false);
    });
  }

  // OOD
  selectedVessel: any = '';
  selectedDockyardVesselState: boolean = false;
  CreateOod() {
    if (this.chitId) {
      // console.log("Update Form Data", this.oodScheduleFormGroup.value);
      let data = {
        dockyard: this.oodScheduleFormGroup.get('dockyard').value,
        ood_name: this.oodContactInfo['First Name'],
        end_time: this.oodScheduleFormGroup.get('end_time').value,
        shedule: this.oodScheduleFormGroup.get('shedule').value,
        start_date: this.oodScheduleFormGroup.get('start_date').value,
        start_time: this.oodScheduleFormGroup.get('start_time').value,
        vessel: this.oodScheduleFormGroup.get('vessel').value,
        ood_email: this.oodContactInfo.email,
        onSiteContact: this.oodScheduleFormGroup.get('onSiteContact').value,
        onSiteContactEmail: this.oodScheduleFormGroup.get('onSiteContactEmail').value,
        supervisor_name: this.oodScheduleFormGroup.get('supervisor_name').value,
        supervisor_email: this.oodScheduleFormGroup.get('supervisor_email').value,
      }
      let port = this.oodScheduleFormGroup.get('dockyard').value;
      this.selectedVessel = this.oodScheduleFormGroup.get('vessel').value;
      if (port) {
        this.getDockyardShipData(port, data, (boo) => {
          if (boo) {
            this.selectedDockyardVesselState = false;
            this.chitService.updateOod(this.chitId, data)
              .subscribe((res) => {
                // console.log("update OOD ", res);
                // this.route.navigate(['/chits']);
                this.dialog.closeAll();
                this.chitService.sendReload("reload");
              }, error => {
                // console.error('There was an error!');
              })
          } else {
            this.selectedDockyardVesselState = true;
            if (this.vesselField) this.vesselField.focus();
          }
        })
      } else {
        console.log("Given data is not valid!!")
      }
    } else {
      console.log("data", this.oodScheduleFormGroup.value);
      let data = {
        dockyard: this.oodScheduleFormGroup.get('dockyard').value,
        ood_name: this.oodScheduleFormGroup.get('ood_name').value,
        end_time: this.oodScheduleFormGroup.get('end_time').value,
        shedule: this.oodScheduleFormGroup.get('shedule').value,
        start_date: this.oodScheduleFormGroup.get('start_date').value,
        start_time: this.oodScheduleFormGroup.get('start_time').value,
        vessel: this.oodScheduleFormGroup.get('vessel').value,
        ood_email: this.oodScheduleFormGroup.get('ood_name').value,
        onSiteContact: this.oodScheduleFormGroup.get('onSiteContact').value,
        onSiteContactEmail: this.oodScheduleFormGroup.get('onSiteContactEmail').value,
        supervisor_name: this.oodScheduleFormGroup.get('supervisor_name').value,
        supervisor_email: this.oodScheduleFormGroup.get('supervisor_email').value,
      }

      let port = this.oodScheduleFormGroup.get('dockyard').value;
      this.selectedVessel = this.oodScheduleFormGroup.get('vessel').value;
      if (port) {
        this.getDockyardShipData(port, data, (boo) => {
          if (boo) {
            this.selectedDockyardVesselState = false;
            this.chitService.addOod(data)
              .subscribe((res) => {
                // console.log("Ood", res)
                this.chitId = res.insertedId;
                // this.route.navigate(['/chits']);
                this.dialog.closeAll();
                this.chitService.sendReload("reload");
                var status = res.status
              }, error => {
                console.error('There was an error!');
              })
          } else {
            this.selectedDockyardVesselState = true;
            if (this.vesselField) this.vesselField.focus();
          }
        })
      } else {
        console.log("Given data is not valid!!")
      }
    }
  }
  getOodData(id) {
    this.chitService.getOodDataList(id)
      .subscribe(
        (response) => {
          var repos = response;
          // console.log('getOodData', repos)
          var getOodData = repos[0]
          this.oodScheduleFormGroup.patchValue(getOodData);
          this.oodNameDetails = getOodData.ood_email ? getOodData.ood_email : ""
          // this.oodScheduleFormGroup.setValue({
          //   ood_name: (getOodData.ood_email ? getOodData.ood_email : ""),
          // });
          // this.oodNameDetails = this.oodScheduleFormGroup
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }
  UpdateOod() {
    let data = {
      dockyard: this.oodScheduleFormGroup.get('dockyard').value,
      ood_name: this.oodScheduleFormGroup.get('ood_name').value,
      end_time: this.oodScheduleFormGroup.get('end_time').value,
      shedule: this.oodScheduleFormGroup.get('shedule').value,
      start_date: this.oodScheduleFormGroup.get('start_date').value,
      start_time: this.oodScheduleFormGroup.get('start_time').value,
      vessel: this.oodScheduleFormGroup.get('vessel').value,
      ood_email: this.oodScheduleFormGroup.get('ood_name').value,
      onSiteContact: this.oodScheduleFormGroup.get('onSiteContact').value,
      onSiteContactEmail: this.oodScheduleFormGroup.get('onSiteContactEmail').value,
      supervisor_name: this.oodScheduleFormGroup.get('supervisor_name').value,
      supervisor_email: this.oodScheduleFormGroup.get('supervisor_email').value,
    }
    // console.log("Updated data", data);
    let port = this.oodScheduleFormGroup.get('dockyard').value;
    this.selectedVessel = this.oodScheduleFormGroup.get('vessel').value;
    if (port) {
      this.getDockyardShipData(port, data, (boo) => {
        if (boo) {
          this.selectedDockyardVesselState = false;
          this.chitService.updateOod(this.chitId, data)
            .subscribe((res) => {
              // console.log("update Ood ", res);
              // this.route.navigate(['/chits']);
              this.dialog.closeAll();
              this.chitService.sendReload("reload");
            }, error => {
              console.error('There was an error!');
            })
        } else {
          this.selectedDockyardVesselState = true;
          if (this.vesselField) this.vesselField.focus();
        }
      })
    } else {
      console.log("Given data is not valid!!")
    }
  }

  // Vessel
  CreateVessel() {
    if (this.chitId) {
      console.log("Update Form Data", this.vesselMasterFormGroup.value);
      this.chitService.updateVessel(this.chitId, this.vesselMasterFormGroup.value)
        .subscribe((res) => {
          // console.log("update Vessel ", res);
          // this.route.navigate(['/chits']);
          this.dialog.closeAll();
          this.chitService.sendReload("reload");
        }, error => {
          console.error('There was an error!');
        })
    } else {
      this.chitService.addVessel(this.vesselMasterFormGroup.value)
        .subscribe((res) => {
          // console.log("Vessel", res)
          this.chitId = res.insertedId;
          // this.route.navigate(['/chits']);
          this.dialog.closeAll();
          this.chitService.sendReload("reload");
          var status = res.status
        }, error => {
          console.error('There was an error!');
        })
    }
  }
  getVesselData(id) {
    this.chitService.getVesselDataList(id)
      .subscribe(
        (response) => {
          var repos = response;
          // console.log('getVesselData', repos)
          var getVesselData = repos[0]
          this.vesselMasterFormGroup.patchValue(getVesselData);
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }
  UpdateVessel() {
    this.chitService.updateVessel(this.chitId, this.vesselMasterFormGroup.value)
      .subscribe((res) => {
        // console.log("update Vessel ", res);
        // this.route.navigate(['/chits']);
        this.dialog.closeAll();
        this.chitService.sendReload("reload");
      }, error => {
        console.error('There was an error!');
      })
  }

  cancelForm() {
    // this._location.back();
    // this.route.navigate(['/chits']);
  }

  userList() {
    this.chitService.getUserData()
      .subscribe(
        (response) => {
          // console.log('response received', response)
          var repos = response;
          this.userListData = repos;
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }
  getShipConfig() {
    // this.locationArry = [];
    // this.shipConfig = []; 
    // this.reportServ.setConfigLastUploadedFile(this.uploadedJsonFile);
    // this.chitService.pullJsonData('http://localhost:3000/json', { port: 'cfbesquimalt', filename: 'shipConfig' }).then((shipConfig) => {
    this.chitService.pullJsonData('json', { port: 'cfbesquimalt', filename: 'shipConfig' }).then((shipConfig) => {

      // console.log("Ship Location ", shipConfig);
      this.VesselName = Object.keys(shipConfig);
      console.log("Ship Location ", this.VesselName);

    }).catch((error) => {
      console.log(error);
    });
  }
  supervisorDetails(value) {
    let shipData = this.userListData.find(el => el.email === value);
    if (shipData) {
      this.supervisorInfo = shipData;
    }
  }
  onSiteContactDetails(value) {
    let shipData = this.userListData.find(el => el.email === value);
    if (shipData) {
      this.onSiteContactInfo = shipData;
    }
  }
  oodDetails(value) {
    let shipData = this.userListData.find(el => el.email === value);
    if (shipData) {
      this.oodContactInfo = shipData;
    }
  }
  // Tagout
  CreateTagout() {
    this.chitService.addTagout(this.tagoutMasterFormGroup.value)
      .subscribe((res) => {
        this.dialog.closeAll();
        this.chitService.sendReload("reload");
        var status = res.status
      }, error => {
        console.error('There was an error!');
      })
  } 
  selectedTagoutID = ''; 
  getTagoutData(id) {
    this.chitService.getTagoutDataList(id)
      .subscribe(
        (response) => {
          var repos = response;
          var getTagoutData = repos[0];
          this.tagoutMasterFormGroup.patchValue(getTagoutData);
          if (repos.length > 0) {
            this.selectedScheduleDuty = getTagoutData['sheduleDuty'];
          }
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }

  rolesListData = [];
  getRolesData(id) {
    this.chitService.getUserRoles(id)
      .subscribe(
        (response) => {
          this.rolesListData = response;
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }

  updateTagout() {
    this.chitService.updateTagout(this.chitId, this.tagoutMasterFormGroup.value)
      .subscribe((res) => {
        this.dialog.closeAll();
        this.chitService.sendReload("reload");
      }, error => {
        console.error('There was an error!');
      })
  }

  selectedScheduleDuty = 'initiate'
  onSelectScheduleDuty(value) {
    this.selectedScheduleDuty = value;
  }

  openRoles() {
    this.lockoutForm = false;
    this.rolesForm = true;
    this.createRolesButton = true;
    this.updateRolesButton = false;
    this.tagoutRolesFormGroup = this._formBuilder.group({
      tagout_id: [this.selectedTagoutID],
      scheduleDuty: ['', Validators.required],
      role: ['', Validators.required],
    });
  }

  createRole() {
    //console.log(this.tagoutRolesFormGroup.value)
    this.chitService.addRole(this.tagoutRolesFormGroup.value)
      .subscribe((res) => {
        this.cancelRoleForm();
        this.getRolesData(this.selectedTagoutID);
      }, error => {
        console.error('There was an error!');
      })
  }

  selectedRoleID = "";
  editRole(id) {
    this.lockoutForm = false;
    this.rolesForm = true;
    this.createRolesButton = false;
    this.updateRolesButton = true;
    this.chitService.getRole(id)
      .subscribe(
        (response) => {
          var getTagoutData = response[0];
          this.tagoutRolesFormGroup.patchValue(getTagoutData);
          if (response.length > 0) {
            this.selectedScheduleDuty = getTagoutData['scheduleDuty'];
            this.selectedRoleID = getTagoutData['_id'];
          }
        },
        error => {
          console.error('Request failed with error')
          console.log(error)
        })
  }

  updateRole(){
    this.chitService.updateRole(this.selectedRoleID, this.tagoutRolesFormGroup.value)
    .subscribe((res) => {
      this.cancelRoleForm();
      this.getRolesData(this.selectedTagoutID);
    }, error => {
      console.error('There was an error!');
    })
  }

  deleteRole(id){
    this.chitService.deleteRole(id)
    .subscribe((res) => {
      this.cancelRoleForm();
      this.getRolesData(this.selectedTagoutID);
    }, error => {
      console.error('There was an error!');
    })
  }

  cancelRoleForm() {
    this.lockoutForm = true;
    this.rolesForm = false;
  }
}