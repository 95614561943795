// import { AuthenticationComponent } from './../authentication/authentication.component';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, Inject, ChangeDetectorRef, HostListener } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../services/language.service';
import { ConfigService } from '../services/config.service';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Logger, CryptoUtils } from 'msal';
import { User } from '../user';
// import { OAuthSettings } from '../../oauth';
// import { Client } from "@microsoft/microsoft-graph-client";
// import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';

import { UserService } from '../services/user.service';
import { ChitService } from '../services/chit.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import * as io from 'socket.io-client';
import { CommunicationService } from '../services/communication.service';

// import { AdminComponent, AdminContentDialog } from '../admin/admin.component';
// import * as userDat from '../../assets/menuData/userInfo.json';
// import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [DatePipe]
})

export class HeaderComponent implements OnInit {
  @ViewChild(MatSidenav, { static: false }) sidenav: MatSidenav;
  @Output() public openContentPage = new EventEmitter();
  @Output() public homePage = new EventEmitter();
  @Input() loggedIn: any = false;
  opened: boolean = false;
  search: any = '';
  selectedMenu: any = '';
  sideNavigate: boolean = false;
  LR_Control: any = 'Left';
  languageSelected: any = 'English';
  childMenu: any = '';
  submenuFourthDataList: any;
  scsConfig: any = { "version": [{ "version": "0.0.2", "notes": "scs" }] };
  sdmsConfig: any = { "version": [{ "version": "0.0.1", "notes": "sdms" }] }

  subscriptions: Subscription[] = [];
  isIframe = false;
  // loggedIn = false;
  userId: any = '';
  userName: any = '';
  user: User;
  usersList: Array<String> = [];
  AppSideNavList: any = []
  profileDetails: any = '';
  userAccessDetails: any = {};
  should_open = false;
  isAdmin = false;
  userLoginInfo: boolean = true;
  loaderState: boolean = false;
  scsURL: any = this.getSCSURL();
  sdmsURL: any = this.getSDMSURL();
  slmsURL: any = this.getSLMSURL();
  userScreenResolution: any;
  AccessObject: any = { "activity": "", "chit": "", "hazard_area": "Y", "first-aid": "N", "aed": "Y", "hazard": "Y", "kiosk": "Y", "laydown": "Y" };

  AppUrlList: any = [
   
    {
      "name": "AOPS",
      "fr_name": "Académie AOPS",
      "key": "slms",
      "childUrl": [   
       
      ],
      "url": "",
      "child": false
    },
    {
      "name": "JSS",
      "fr_name": "JSS",
      "key": "yourship",
      "childUrl": [
      ]
    },
   
    
  ]
  broadcastSubs: Subscription;
 overlapWidth:any=100;
 userLoginDetailsWindow: boolean = false;
  constructor(private datePipe: DatePipe, private router: Router, private communicationServ: CommunicationService, private userService: UserService, private cdr: ChangeDetectorRef, private translate: TranslateService, private dialog: MatDialog, private languageChange: LanguageService, public configService: ConfigService, private broadcastService: BroadcastService, private authService: MsalService, private http: HttpClient, private chitService: ChitService) {
    if (this.loggedIn) {
      this.translate.addLangs(['en', 'fr']);
      this.translate.setDefaultLang('en');
      this.translate.use('en');
    }
    this.broadcastSubs = this.communicationServ.getInstance().subscribe((data: any) => {
      if (data.src === "main") {
        if (data.event === 'dologin') {
          this.login();
        }
      }
      if (data.src === "header") {
        if (data.event === 'loader') {
          this.loaderState = false
        }
      }
    })
   this.overlayWidth();


// alert(this.isTouchDevice());
  }
  isTouchDevice(){
    return window.ontouchstart !== undefined;
}
overlayWidth(){
  if(this.selectedMenu){
    this.overlapWidth = (window.innerWidth-740);
  }else{
    this.overlapWidth = (window.innerWidth-370);
  } 
}
  getSCSURL() {
    let hostname = window.location.hostname;
    if (hostname === 'localhost') {
      let url = 'http://localhost:4201/';
      // let url = 'https://navyscs.azurewebsites.net/';
      this.userService.scsURL = url;
      return url;
    } else if (hostname === 'navyapp.azurewebsites.net') {
      let url = 'https://navyscs.azurewebsites.net/';
      this.userService.scsURL = url;
      return url;
    } else if (hostname === 'navyapp.canadacentral.cloudapp.azure.com') {
      let url = 'http://navyapp.canadacentral.cloudapp.azure.com:4200/';
      this.userService.scsURL = url;
      return url;
    } else if (hostname === 'navy.datifex.net') {
      let url = 'https://scs.navy.datifex.net/';
      this.userService.scsURL = url;
      return url;
    } else if (hostname === 'navy.datifex.com') {
      let url = 'https://scs.navy.datifex.com/';
      this.userService.scsURL = url;
      return url;
    }
  }

  getSDMSURL() {
    let hostname = window.location.hostname;
    if (hostname === 'localhost') {
      let url = 'http://localhost:4202/';
      // let url = 'https://navysdms.azurewebsites.net/';
      this.userService.sdmsURL = url;
      return url;
    } else if (hostname === 'navyapp.azurewebsites.net') {
      let url = 'https://navysdms.azurewebsites.net/';
      this.userService.sdmsURL = url;
      return url;
    } else if (hostname === 'navyapp.canadacentral.cloudapp.azure.com') {
      let url = 'http://navyapp.canadacentral.cloudapp.azure.com:4201/';
      this.userService.sdmsURL = url;
      return url;
    } else if (hostname === 'navy.datifex.net') {
      let url = 'https://sdms.navy.datifex.net/';
      this.userService.sdmsURL = url;
      return url;
    } else if (hostname === 'navy.datifex.com') {
      let url = 'https://sdms.navy.datifex.com/';
      this.userService.sdmsURL = url;
      return url;
    }
  }

  getSLMSURL() {
    let hostname = window.location.hostname;
    if (hostname === 'localhost') {
      let url = 'http://localhost:4201/';
      // let url = 'https://slms.navy.datifex.net/';
      this.userService.slmsURL = url;
      return url;
    } else if (hostname === 'navyapp.azurewebsites.net') {
      let url = 'https://slms.navy.datifex.com/';
      this.userService.slmsURL = url;
      return url;
    } else if (hostname === 'navyapp.canadacentral.cloudapp.azure.com') {
      let url = 'http://navyapp.canadacentral.cloudapp.azure.com:4204/';
      this.userService.slmsURL = url;
      return url;
    } else if (hostname === 'navy.datifex.net') {
      let url = 'https://slms.navy.datifex.net/';
      this.userService.slmsURL = url;
      return url;
    } else if (hostname === 'navy.datifex.com') {
      let url = 'https://slms.navy.datifex.com/';
      this.userService.slmsURL = url;
      return url;
    }
  }

  getNotificationLength() {
    let arrLen = this.notificationList.filter(el => el.message != '');
    return arrLen.length;
  }

  profileDialog() {
    this.dialog.open(ProfilePopup, { data: { profileDetails: this.profileDetails, accountEmail: this.accountEmail} });
  }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.communicationServ.getInstance().next(data);
  }

  ngOnInit() {
    //this.openDialog();
    // this.getData()
    // this.getUserActivityInfo();
    var scs_url = 'https://nodesdms.azurewebsites.net/version.json';
    var sdms_url = 'https://nodesdms.azurewebsites.net/version.json';
    //var scs_url = 'http://localhost:4201/assets/appData/version.json';
    //var sdms_url = 'http://localhost:4202/assets/appData/version.json';

    if (this.loggedIn) {
      this.configService.getConfig(scs_url).subscribe((data: {}) => {
        console.log("scs", data);
        this.scsConfig = data['scs'];
      })

      this.configService.getConfig(sdms_url).subscribe((data: {}) => {
        console.log("sdms", data);
        this.sdmsConfig = data['sdms'];
      })
    }

    /**
     * Login scripts 
     */
    let loginSuccessSubscription: Subscription;
    let loginFailureSubscription: Subscription;

    this.isIframe = window !== window.parent && !window.opener;

    this.checkAccount();

    loginSuccessSubscription = this.broadcastService.subscribe('msal:loginSuccess', () => {
      this.checkAccount();
    });

    loginFailureSubscription = this.broadcastService.subscribe('msal:loginFailure', (error) => {
      console.log('Login Fails:', error);
    });

    this.subscriptions.push(loginSuccessSubscription);
    this.subscriptions.push(loginFailureSubscription);

    if (this.loggedIn) {
      this.authService.handleRedirectCallback((authError, response) => {
        if (authError) {
          console.error('Redirect Error: ', authError.errorMessage);
          return;
        }
        console.log('Redirect Success: ', response.accessToken);
      });

      this.authService.setLogger(new Logger((_logLevel, message, _piiEnabled) => {
        console.log('MSAL Logging: ', message);
      }, {
        correlationId: CryptoUtils.createNewGuid(),
        piiLoggingEnabled: false
      }));
    }
    if (window.innerWidth < 1024) {
      this.webView = false;
    } else {
      if(this.isTouchDevice()){
        this.webView = false;
      }else{
        this.webView = true;
      }
    }
  }

  ngAfterViewInit() {
    if (this.loggedIn) {
      this.translate.addLangs(['en', 'fr']);
      this.translate.setDefaultLang('en');
      this.translate.use('en');
    }
    this.cdr.detectChanges();

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  getAccessToken(cbck) {
    if (!!this.authService.getAccount()) {
      console.log("this.authService", this.authService);
      var accountEmail = this.authService['account']['userName'];
      var userId = this.authService['account']['accountIdentifier'];
      var userName = this.authService['account']['name'];
      let loginInfo = { userId, userName: userName, accountEmail: accountEmail };
      this.userService.getAccessToken(loginInfo).subscribe(data => {
        if (data['accessToken']) {
          this.userService.accessToken = data['accessToken'];
          this.userService.refershToken = data['refershToken'];
          this.chitService.accessToken = data['accessToken'];
          this.chitService.refershToken = data['refershToken'];
          cbck(true);
        } else {
          cbck(false);
        }
      },
        error => {
          console.log(error);
          cbck(false)
        });
    } else {
      cbck(false);
    }
  }
  accountEmail: any = ''
  checkAccount() {
    this.getAccessToken((boo) => {
      if (boo) {
        this.loggedIn = !!this.authService.getAccount();
        this.loggedIn ? this.loadData((data) => {
          console.log("loggedIn: ", data);
        }) : '';
        if (this.loggedIn) {
          this.sideNavigation();
          this.translate.addLangs(['en', 'fr']);
          this.translate.setDefaultLang('en');
          this.translate.use('en');
          this.cdr.detectChanges();
          if (this.authService['account']) {
            // console.log("this.authService['account']", this.authService['account']);
            var accountEmail = this.authService['account']['userName'];
            this.userId = this.authService['account']['accountIdentifier'];
            this.userName = this.authService['account']['name']
            this.accountEmail = accountEmail;
            this.userLoginEmail = this.authService['account']['userName'];
            this.userScreenResolution = screen.width + '*' + screen.height;
            this.init_socket();
            this.getComponenetAcceess(accountEmail);
            this.getRoleAccess(accountEmail);
            this.checkAdmin(accountEmail);
            this.getMessageData();
            // console.log("window.innerWidth;", window.innerWidth + '*' + window.innerHeight);
            let loginInfo = { userId: this.userId, userName: this.userName, accountEmail: accountEmail, accessToken: this.userService.accessToken, refershToken: this.userService.refershToken };
            // console.log("accessObject : ", loginInfo)
            this.broadcastInfo({ src: 'header', event: 'loginstatus', data: loginInfo, key: this.loggedIn });
          }
        }
      }
    })
  }

  login() {
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

    if (isIE) {
      this.authService.loginRedirect();
    } else {
      this.authService.loginPopup().then(_response => {
        //console.log("loginPopup ", response)
      })
        .catch(err => {
          console.log("userCancelledError ", err)
        });
    }
  }

  checkAdmin(userName) {
    var users = [];
    this.userService.getUserData()
      .subscribe((res) => {
        Object.keys(res).forEach(key => {
          users.push(res[key])
        })
        // var result = users.filter(t => t.teamsid.toLowerCase().trim().replaceAll(/\s/g, '') === userName.toLowerCase().trim().replaceAll(/\s/g, ''));
        var result = users.filter(t => (t.teamsid.toLowerCase().trim().replaceAll(/\s/g, '') === userName.toLowerCase().trim().replaceAll(/\s/g, '')) || (t.email.toLowerCase().trim().replaceAll(/\s/g, '') === userName.toLowerCase().trim().replaceAll(/\s/g, '')));
       
        this.profileDetails = result;
        if (result.length > 0) {
          if (result[0].Admin === 'Y') {
            this.isAdmin = true;
          }
        }
      });
  }

  /**Set AccessObject for access according to roles**/
  getRoleAccess(userName) {
    var users = [];
    this.userService.getUserData()
      .subscribe((res) => {
        Object.keys(res).forEach(key => {
          users.push(res[key])
        })
        // var result = users.filter(t => t.teamsid.toLowerCase().trim().replaceAll(/\s/g, '') === userName.toLowerCase().trim().replaceAll(/\s/g, ''));
        var result = users.filter(t => (t.teamsid.toLowerCase().trim().replaceAll(/\s/g, '') === userName.toLowerCase().trim().replaceAll(/\s/g, '')) || (t.email.toLowerCase().trim().replaceAll(/\s/g, '') === userName.toLowerCase().trim().replaceAll(/\s/g, '')));
        this.profileDetails = result;

        if (result.length > 0) {
          this.userAccessDetails = result[0];
          // alert("Your Email Id match : " + this.userAccessDetails.email);
          // let obj = Object.assign({},this.userAccessDetails, this.AccessObject);
          this.broadcastInfo({ src: 'header', event: 'profileDetails', data: this.userAccessDetails, key: '' });
          if (result[0].Role === 'OOD') {
            var sd = this.AppUrlList.find(j => j.key === 'sdms');
            this.AccessObject.activity = "Y";
            this.AccessObject.chit = "Y";
          }
          else if (result[0].Role === 'CO' || result[0].Role === 'DPO' || result[0].Role === 'QM' || result[0].Role === 'MSEO' || result[0].Role === 'CSEO' || result[0].Role === 'CCFL' || result[0].Role === 'CCFP' || result[0].Role === 'WFM' || result[0].Role === 'QHM') {
            this.AccessObject.activity = "Y";
          }
        }else{
          // alert("Your Email Id Not match : " + userName + " Please contact our admin");
        }
      });
  }
userLoginText: any;
userLoginDetails: any;
userLoginEmail: any;
  /**Render sidebar data according to user access**/
  getComponenetAcceess(userName) {
    var users = [];
    var default_user = [
      { "_id": "610d3dbfd6b4251d4cc9b220", "email": "raj.t@datifex.com", "SCS": "Y", "SDMS": "Y", "MARTECH": "Y", "MARLANT": "Y", "MARPAC": "Y", "Last Name": "Thulasidoss", "First Name": "Raj", "Rank E": "Civ", "Rank F": "Civ", "Org E": "Datifex, Inc.", "Org F": "Datifex, Inc.", "Role": null, "Cell": "(902) 439-4509", "Location": null, "Language": "E", "Admin": "Y", "hazard": "Y", "aed": "N", "first-aid": "Y", "spill-kits": "N", "evacuation-posters": "N", "kiosk": "Y", "laydown": "Y", "contractors": "N", "portable-generators": "N", "fork-lifts": "N", "cranes": "N", "FMF": "Y", "WFM": "Y" }
    ];
    // ----------------------------------------- working code -------------------    
    this.userService.getUserData()
      .subscribe((response) => {
        users = response;
        // console.log("users=", users);
        var result = users.filter(t => (t.teamsid.toLowerCase().trim().replaceAll(/\s/g, '') === userName.toLowerCase().trim().replaceAll(/\s/g, '')) || (t.email.toLowerCase().trim().replaceAll(/\s/g, '') === userName.toLowerCase().trim().replaceAll(/\s/g, '')));
        this.profileDetails = result;
        if (result.length > 0) {
          this.userAccessDetails = result[0];
          // alert("You are successfully logged in : " + userName);
          this.userLoginDetailsWindow = true;
          this.userLoginDetails = "You are successfully logged in"
        } else {
          console.log("login failed for user", userName);
          this.userAccessDetails = default_user[0];
          // alert("Your Email Id Not match : " + userName + " Please contact our admin");
          this.userLoginDetailsWindow = true;
          // this.userLoginDetails = "Your Email Id Not match Please contact our admin"
        }

        if (this.userAccessDetails) {
          // console.log("userAccessDetails", this.userAccessDetails, this.AppUrlList)
          this.updateMenuAccess(this.userAccessDetails);
          this.broadcastInfo({ src: 'header', event: 'profileDetails', data: this.userAccessDetails, key: '' });
          this.menuAccess();
        }
      },
        error => {
          console.log('Request failed with error')
          console.log(error)
        })
  }
  menuAccess(){
    if (this.userAccessDetails) {
      if (this.AppUrlList) {
        this.AppUrlList.forEach((element, key) => {
          if (element.key == 'sdms') {
            if (this.userAccessDetails.SDMS === 'Y') {
              element.disabled = false;
            } else {
              element.disabled = true;
            }
          }
          if (element.key == 'scs') {
            if (this.userAccessDetails.SCS === 'Y') {
              element.disabled = false;
            } else {
              element.disabled = true;
            }
          }
          if (element.key == 'poc') {
            if (this.userAccessDetails.POC === 'Y') {
              element.disabled = false;
            } else {
              element.disabled = true;
            }
          }
          if (element.key == 'slms') {
            if (this.userAccessDetails.SLMS === 'Y') {
              element.disabled = false;
            } else {
              element.disabled = true;
            }
          }
          if (element.key == 'Fleetway') {
            if (this.userAccessDetails.fleetway === 'Y') {
              element.disabled = false;
            } else {
              element.disabled = true;
            }
          }
          if (element.key == 'ourship') {
            if (this.userAccessDetails.ourship === 'Y') {
              element.disabled = false;
            } else {
              element.disabled = true;
            }
          }
          if (element.key == 'yourship') {
            if (this.userAccessDetails.yourship === 'Y') {
              element.disabled = false;
            } else {
              element.disabled = true;
            }
          }
          // console.log("data", element.key);
        });
      }
      
      this.AppSideNavList = this.AppUrlList;
    }
  }
  allowClearMessage() {
    let emailArr = ['raj.t@datifex.com', 'prabha.r@datifex.com', 'gokul.r@datifex.com', 'vengad.s@datifex.com'];
    return emailArr.some(el => el.toLocaleLowerCase() === this.accountEmail.toLocaleLowerCase())
  }

  chitActivityList = [];
  curUserChitList = []
  getChitData() {
    this.userService.getChitData()
      .subscribe((response) => {
        this.chitActivityList = response;
        this.chitActivityList.forEach(element => {
          if (element.azureIdCtrl) {
            if (element.azureIdCtrl == this.profileDetails[0]['_id']) {
              this.curUserChitList.push(element);
            }
          }
        });
        this.loadServerData();
        console.log("curUserChitList", this.curUserChitList)
      },
        error => {
          console.log('Request failed with error')
          console.log(error)
        })

  }
  notificationList: any = [];
  getMessageData() {
    // this.notificationList = [];
    this.userService.getMessageData()
      .subscribe((response) => {
        console.log("message", response);
        if (response.result == 'success') {
          this.notificationList = response.notifications;
          if (this.notificationList.length > 0) {
            this.notificationList = this.notificationList.filter(data => {
              if (data['receiver'].length <= 0) {
                return data;
              } else if (data['receiver']) {
                data['receiver'].some(el => el['email'].toLowerCase() === this.accountEmail.toLowerCase())
                return data;
              }
            })
            this.notificationList = this.notificationList.reverse();
          }
        }
      },
        error => {
          console.log('Request failed with error');
          console.log(error)
        })
  }

  clearAllMessage() {
    var r = confirm("Are you sure want to delete the all messages !!");
    if (r == true) {
      this.userService.clearAllMessage()
        .subscribe((response) => {
          if (response['result'] === 'success') {
            this.notificationList = [];
          }
        }, error => {
          console.log('Request failed with error');
          console.log(error)
        })
    }
  }

  deleteMessage(id) {
    var r = confirm("Are you sure want to delete message !!");
    if (r == true) {
      this.userService.deleteMessage(id)
        .subscribe((response) => {
          if (response['result'] === 'success') {
            this.notificationList = this.notificationList.filter(el => el.id !== id);
          }
        }, error => {
          console.log('Request failed with error');
          console.log(error)
        })
    }
  }



  /* * *
  * get meta data from the server
  * * */
  portList = ['cfbhalifax', 'cfbesquimalt'];
  halifaxShipList = [];
  esquimaltShipList = [];
  chitShipList = [];
  loadServerData(cbck = null) {
    let port = '';
    this.portList.forEach(e => {
      this.userService.getShipData('json', { port: e, filename: 'shipObjects' }).then((res) => {
        if (res['result'] != 'failed') {
          if (e == 'cfbhalifax') {
            this.halifaxShipList = res['ships'];
            if (this.loginUserShip.length > 0) {
              this.loginUserShip.forEach(elem => {
                this.halifaxShipList.forEach(element => {
                  if (element.ship) {
                    if (element.ship == elem.vessel) {
                      this.chitShipList.push(element);
                      port = e;
                    }
                  }
                });
              });
            }
          }
          if (e == 'cfbesquimalt') {
            this.esquimaltShipList = res['ships'];
            if (this.loginUserShip.length > 0) {
              this.loginUserShip.forEach(elem => {
                this.esquimaltShipList.forEach(element => {
                  if (element.ship) {
                    if (element.ship == elem.vessel) {
                      this.chitShipList.push(element);
                      port = e;
                    }
                  }
                });
              });
            }
          }

          setTimeout(() => {
            if (port != '') {
              let commandingData = { 'id': this.profileDetails[0]['_id'], 'port': port, 'ship': this.chitShipList };
              this.broadcastInfo({ src: 'header', event: 'commandingShip', data: commandingData });
            } else {
              this.broadcastInfo({ src: 'header', event: 'commandingData', data: "yes", key: "" });
              console.log("No vessel has been assigned to you");
              // let commandingData = { 'id': this.profileDetails[0]['_id'], 'port':'cfbesquimalt', 'ship': this.chitShipList };
              // this.broadcastInfo({ src: 'header', event: 'commandingShip', data: commandingData });
            }
            if (cbck) {
              cbck();
            }
          }, 200);

        } else {
          if (cbck) {
            cbck();
          }
        }
      });
    });
  }

  loginUserShip: any;
  getUserActivityInfo(cbck) {
    this.chitService.getOodData().subscribe(response => {
      var shipLocationMatch = response.filter(t => t.ood_email == this.profileDetails[0].email);
      console.log("shipLocationMatch", shipLocationMatch[0]);
      this.loginUserShip = shipLocationMatch;
      this.loadServerData(() => {
        cbck();
      })
    })
  }

  /* * *
  * get meta data from the server
  * * */
  shipConfig
  locationArry
  loadServerConfigData() {
    this.portList.forEach(e => {
      this.userService.getShipData('json', { port: e, filename: 'shipConfig' }).then((res) => {
        if (res['result'] != 'failed') {
          console.log('shipconfigData' + e, res);
          this.shipConfig = res;
          this.userService.getShipData('json', { port: e, filename: 'shipPosition' }).then((Locations) => {
            if (Locations['result'] != 'failed') {
              this.locationArry = Locations;
            }
          })
          // this.shipMetaData = shipMetaData;
        } else {
          // this.openPublishDialog('metadata not found !!');
        }
      });
    })
  }

  shipDataAssign(data) {
    data.forEach(obj => {
      let location = (obj.location ? obj.location.split(" ").join("").replace("/", "_") : "");
      this.locationArry['position'].forEach(loc => {
        if (location === loc.location) {
          if (this.shipConfig[obj.ship]) {

          }
        }
      })
    })
  }

  updateMenuAccess(userAccess) {
    console.log("userAccess ", userAccess);
    console.log("this.AppUrlList ", this.AppUrlList)
    this.AppUrlList.forEach(mainMenu => {
      if (mainMenu['childUrl']) {
        mainMenu['childUrl'].forEach(childMenu => {
          let keyName = childMenu['keyName'];
          let parent = childMenu['parent'];
          console.log("userAccess[parent]", userAccess[parent])
          if (parent) {
            childMenu.disabled = userAccess[parent] == 'Y' ? false : (userAccess[parent] == 'N' ? true : childMenu.disabled);
          } else {
            childMenu.disabled = userAccess[keyName] == 'Y' ? false : (userAccess[keyName] == 'N' ? true : childMenu.disabled);
          }
          if (childMenu['childList']) {
            childMenu['childList'].forEach(childList => {
              let childKeyName = childList['keyName'];
              childList.disabled = userAccess[childKeyName] == 'Y' ? false : (userAccess[childKeyName] == 'N' ? true : childList.disabled);
            })
          }
        })
      }
    });
  }

  logout() {
    this.authService.logout();
  }

  // Silently request an access token
  // async getAccessToken(): Promise<string> {
  //   let result = await this.authService.acquireTokenSilent(OAuthSettings)
  //     .catch((reason) => {
  //       console.log('Get token failed', JSON.stringify(reason, null, 2));
  //     });
  //   if (result) {
  //     return result.accessToken;
  //   }
  //   return null;
  // }

  // private async getUser(): Promise<User> {
  //   if (!this.loggedIn) return null;

  //   let graphClient = Client.init({
  //     // Initialize the Graph client with an auth
  //     // provider that requests the token from the
  //     // auth service
  //     authProvider: async (done) => {
  //       let token = await this.getAccessToken()
  //         .catch((reason) => {
  //           done(reason, null);
  //         });

  //       if (token) {
  //         done(null, token);
  //       } else {
  //         console.log("Could not get an access token ")
  //         return null;
  //       }
  //     }
  //   });

  //   // Get the user from Graph (GET /me)
  //   let graphUser: MicrosoftGraph.User = await graphClient
  //     .api('/me')
  //     .select('displayName,id')
  //     .get();

  //   let user = new User();
  //   user.userName = graphUser.displayName;
  //   user.userId = graphUser.id;
  //   return user;
  // }

  /**
   * get account detail
   */
  getAccountDetail() {
    this.user = new User();
    this.user.userName = this.authService['account']['name'];
    this.user.userId = this.authService['account']['accountIdentifier'];
  }

  loadData(_callback) {
    var _this = this;
    this.getAccountDetail();
  }

  /* * * * *
 * init socket service
 * * * * * */
  socket: any;
  // socketPath = 'http://localhost:3000';
  socketPath = 'https://nodesdms.azurewebsites.net';
  init_socket() {
    console.log('init_socket');
    var _this = this;
    let socketPath = this.socketPath || 'http://localhost:3000';
    if (_this.userId) {
      let socketport = `${socketPath}?userId=${_this.userId}&username=${_this.userName}`;
      _this.socket = io.connect(socketport);
      _this.socket.on('connect', onConnect);
      _this.socket.on('disconnect', onDisconnect);
      _this.socket.on('connect_error', onError);
      _this.socket.on('reconnect_error', onError);

      function onConnect(_evt) {
        console.log("navyapp socket connected!!");
        writeToScreen("CONNECTED");

        _this.socket.on("updateNotification", message => {
          _this.updateNotification(message);
        });
      }

      function onDisconnect(_evt) {
        writeToScreen("DISCONNECTED");
        console.log("socket disconnected!!");
      }
      function onError(message) {
        writeToScreen('<span style="color: red;">ERROR:</span> ' + message);
      }
      function writeToScreen(message) {
        var pre = document.createElement("p");
        pre.style.wordWrap = "break-word";
        pre.innerHTML = message;
        //output.appendChild(pre);
      }
    }
  }

  /**
   * method recive info broadcast message through socket
   */
  updateNotification(notifications) {
    console.log(" navyapp updateNotification ");
    this.notificationList = notifications;
    if (this.notificationList.length > 0) {
      this.notificationList = this.notificationList.filter(data => {
        if (data['receiver'].length <= 0) {
          return data;
        } else if (data['receiver'].some(el => el['teamsid'].toLowerCase() === this.accountEmail.toLowerCase())) {
          return data;
        }
      })
      this.notificationList = this.notificationList.reverse();
    }
  }

  homeScreen() {
    this.homePage.emit()
  }

  sideNavigation() {
    this.opened = true;
    this.sidenav.toggle();
    this.sideNavigate = !this.sideNavigate;
    this.selectedMenu = '';
    this.overlayWidth();
  }

  menuActive(pageData) {
    if (pageData.childUrl.length > 0) {

      this.selectedMenu = pageData;
      this.childMenu = pageData.childUrl;
      if (window.innerWidth < 768) {
        this.opened = false;
        this.sidenav.toggle();
        this.sideNavigate = !this.sideNavigate;
      }
    } else {
      this.selectedMenu = pageData;
      this.openContent(pageData);
    }
    this.overlayWidth();
  }

  openMenuContent(pageData) {
    // this.getActive(pageData);
    if (pageData.childList) {
      this.getActive(pageData);
    } else {
      this.selectedMenu = pageData;
      this.openContent(pageData);
    }
    this.overlayWidth();
  }
  getActive(menuData) {
    let idnx = this.AppSideNavList.findIndex(e => e.key == this.selectedMenu.key);
    if (idnx > -1) {
      let idx = this.childMenu.findIndex(e => e.keyName == menuData.keyName);
      if (idx > -1) {
        this.AppSideNavList[idnx]['childUrl'][idx]['child'] = !this.AppSideNavList[idnx]['childUrl'][idx]['child'];
      } else {
        this.childMenu.forEach((element, indx) => {
          if (element.childList) {
            let idx = element.childList.findIndex(e => e.keyName == menuData.keyName);
            if (idx > -1) {
              this.AppSideNavList[idnx]['childUrl'][indx]['childList'][idx]['child'] = !this.AppSideNavList[idnx]['childUrl'][indx]['childList'][idx]['child'];
            } else {
              element.childList.forEach((elem, inx) => {
                if (elem.childList) {
                  let idx1 = elem.childList.findIndex(e => e.keyName == menuData.keyName);
                  if (idx1 > -1) {
                    this.AppSideNavList[idnx]['childUrl'][indx]['childList'][inx]['childList'][idx1]['child'] = !this.AppSideNavList[idnx]['childUrl'][indx]['childList'][inx]['childList'][idx1]['child'];
                  } else {
                    elem.childList.forEach((ele, idx) => {
                      if (ele.childList) {
                        let idx2 = ele.childList.findIndex(e => e.keyName == menuData.keyName);
                        if (idx2 > -1) {
                          this.AppSideNavList[idnx]['childUrl'][indx]['childList'][inx]['childList'][idx]['childList'][idx2]['child'] = !this.AppSideNavList[idnx]['childUrl'][indx]['childList'][inx]['childList'][idx]['childList'][idx2]['child'];
                        } else {
                          ele.childList.forEach((el, i) => {
                            if (el.childList) {
                              let idx3 = el.childList.findIndex(e => e.keyName == menuData.keyName);
                              if (idx3 > -1) {
                                this.AppSideNavList[idnx]['childUrl'][indx]['childList'][inx]['childList'][idx]['childList'][i]['child'] = !this.AppSideNavList[idnx]['childUrl'][indx]['childList'][inx]['childList'][idx]['childList'][i]['child'];
                              } else {
                              }
                            }
                          });
                        }
                      }
                    });
                  }
                }
              });
            }
          }
        });
      }

    }


  }
  openAdmin() {
    this.sideNavigate = !this.sideNavigate;
    this.opened = false;
    this.sidenav.toggle();
    this.userLoginInfo = false;
  }
  openContent(e) {
    if(e.keyName !='visitor_registration' && e.keyName !='visitor_scan'){
    this.submenuDataList = '';
    this.loaderState = true;
    this.submenuFourthDataList = '';
    this.router.navigate(['/']);
    this.sideNavigate = !this.sideNavigate;
    this.userLoginInfo = false;
    this.selectedMenu = '';
    this.overlayWidth();
    this.opened = false;
    this.sidenav.toggle();
    } else if(e.keyName =='visitor_registration'){  
      this.router.navigate(['/visitor'])
      this.submenuDataList = '';
      this.sideNavigate = !this.sideNavigate;
      this.opened = false;
      this.sidenav.toggle();
      this.userLoginInfo = false;
    }else if(e.keyName =='visitor_scan'){  
      this.router.navigate(['/scanner'])
      this.submenuDataList = '';
      this.sideNavigate = !this.sideNavigate;
      this.opened = false;
      this.sidenav.toggle();
      this.userLoginInfo = false;
    }
    if (e.url == 'commanding') {
      // this.getChitData();
      this.getUserActivityInfo(() => {
        this.openContentPage.emit(e);
      });
    } else if(e.keyName !='visitor_registration' && e.keyName != "visitor_scan") {
      this.openContentPage.emit(e);
      this.opened = false;
      this.sidenav.toggle();
    }
    if (e.keyName == 'video_course') {
      this.loaderState = false;
    } else if(e.keyName == "video_seawater") {
      this.loaderState = false;
    } else if(e.keyName == "video_mechanical") {
      this.loaderState = false;
    } else if(e.keyName == "video_rqs3") {
      this.loaderState = false;
    } else if(e.keyName == "video_fleetview") {
      this.loaderState = false;
    } else if(e.keyName == "video_sos") {
      this.loaderState = false;
    }else if(e.keyName == "video_radhaz") {
      this.loaderState = false;
    }
  }

  /* * * *
   * search object
   * * * * */
  overlaySidenav() {
    this.sideNavigate = !this.sideNavigate;
    this.sidenav.toggle();
    this.opened = false;
    this.selectedMenu = '';
    this.overlayWidth();
  }

  /* * * *
   * search object
   * * * * */
  searchValue(e) {
    console.log(e.target.value);
    this.search = e.target.value;
  }

  /* * * *01-12-2020
   * search clear
   * * * * */
  searchClear(e) {
    this.search = '';
  }

  leftrightControl(e) {
    if (e == "right") {
      this.LR_Control = "Right";

    }
    if (e == "left") {
      this.LR_Control = "Left";
    }
  }

  openDialog2() {
    const dialogRef = this.dialog.open(HeaderContentDialog, { data: { versionNotes: this.sdmsConfig, versionName: "SDMS" } });
    //    console.log("data=",this.message);
    dialogRef.afterClosed().subscribe(_result => {
      // console.log(`Dialog result: ${result}`);
    });
    dialogRef.disableClose = false;
  }

  openDialog3() {
    const dialogRef = this.dialog.open(HeaderContentDialog, { data: { versionNotes: this.scsConfig, versionName: "SCS" } });
    //    console.log("data=",this.message);
    dialogRef.afterClosed().subscribe(_result => {
      // console.log(`Dialog result: ${result}`);
    });
    dialogRef.disableClose = false;
  }

  // nxtLanguage: any = 'English';
  onChangeLanguage(langName) {
    // let langKey;
    if (langName == 'English') {
      this.languageChange.changeLanguage('fr');
      this.translate.use('fr');
      this.languageSelected = 'French';
      // this.nxtLanguage = 'French';
    }
    if (langName == 'French') {
      this.languageChange.changeLanguage('en');
      this.translate.use('en');
      // this.nxtLanguage = 'English';
      this.languageSelected = 'English';
    }
  }
getPath(cbck=null,e=null){
  let path= e.path || (e.composedPath && e.composedPath());
  cbck(path);
}
  submenuDataList: any = '';
  hoverEnterMenu(e, i) {
    // console.log("enter",e.target.id);
    this.getPath((data) => {
      // console.log("enter",data);
    var elem =data
    if (elem.length > 0) {
      elem.forEach(element => {
        if (element.id != "" && (element.className?element.className.indexOf('submenuEvent')>-1:false)){// (element.className == "submenuEvent mat-list-item" || element.className == "mat-list-item submenuEvent" || element.className == "submenuEvent mat-list-item ng-star-inserted")) {
          //  if()
          this.submenuFourthDataList = '';
          if (!this.childMenu[i].childList) {
            // setTimeout(() => {
            this.submenuDataList = '';

            return
            // }, 0);
          }
          this.childMenu[i].child = true;
          if (this.childMenu[i]) {
            this.submenuDataList = this.childMenu[i];
            var eleId = document.getElementById(element.id)
            if (eleId != null) {
              var rect = eleId.getBoundingClientRect();
            }
            // setTimeout(() => {
            if (document.getElementById(element.id + '_sub') != null) {
              if (window.innerHeight / 1.5 < rect.top) {
                document.getElementById(element.id + '_sub').style.bottom = rect.bottom / 6 + 'px';
                document.getElementById(element.id + '_sub').style.top = 'unset';
              } else {
                document.getElementById(element.id + '_sub').style.top = rect.top + 'px';
                document.getElementById(element.id + '_sub').style.bottom = 'unset';
              }
              document.getElementById(element.id + '_sub').style.left = rect.right + 'px';
            }
          }
        }else if (element.id != "" && (element.className?element.className.indexOf('subThirdmenuEvent')>-1:false)) {// else if (element.id != "" && (element.className.indexOf('subThirdmenuEvent')>-1element.className == "subThirdmenuEvent mat-list-item" ||"mat-list-item subThirdmenuEvent" || element.className == "subThirdmenuEvent mat-list-item ng-star-inserted" || element.className == "fourth_submenu ng-star-inserted")) {
          if (!this.submenuDataList.childList[i].childList) {
            // setTimeout(() => {
            this.submenuFourthDataList = '';
            return
            // }, 0);

          }
          this.submenuFourthDataList = this.submenuDataList.childList[i];
          var eleId = document.getElementById(element.id)
          if (eleId != null) {
            var rect = eleId.getBoundingClientRect();
          }
          // setTimeout(() => {
          if (document.getElementById(element.id + '_sub') != null) {
            if (window.innerHeight / 1.8 < rect.top) {
              document.getElementById(element.id + '_sub').style.bottom = rect.bottom / 30 + 'px';
              document.getElementById(element.id + '_sub').style.top = 'unset';
            } else {
              document.getElementById(element.id + '_sub').style.top = rect.top + 'px';
              document.getElementById(element.id + '_sub').style.bottom = 'unset';
            }
            document.getElementById(element.id + '_sub').style.left = rect.right + 'px';
          }
        } else {
          if (element.id == "sideNav" || element.id == "overlay-sidenav") {
            setTimeout(() => {
              this.submenuDataList = '';
              this.submenuFourthDataList = '';
              return
            }, 100);
          }
        }
      });
    }
  },e)
  }

  hoverLeaveMenu(e, i) {
    // console.log("leave",e.target.id);
    // this.childMenu[i].child=false;
    // var elem = e.path;
    // var findState = false;
    // // this.submenuDataList='';
    // if (elem.length > 0) {
    //   elem.forEach(element => {
    //     if (element.id != "" && element.className == "submenuEvent mat-list-item") {
    //       // this.childMenu[i].child=true;
    //       // this.submenuDataList = this.childMenu[i];
    //       // console.log("submenuDataList",this.submenuDataList);
    //       // var eleId = document.getElementById(element.id)
    //       // let rect = eleId.getBoundingClientRect();
    //       // console.log(rect)
    //       // document.getElementById(element.id+'_sub').style.top=rect.top+'px';

    //       findState = true;
    //     }

    //   });
    //   if (!findState) {
    //     setTimeout(() => {

    //     }, 2000);

    //     }
    //   }
  }
  getUserProfile(username) {
    return username[0];

  }
  getDateFormat(date) {
    return this.datePipe.transform(date, 'MMMM d, y');
  }
  getDateHours(date) {
    var startCDate: any = new Date()
    var endCDate: any = new Date(date)
    var delta = Math.abs(endCDate - startCDate) / 1000;
    // calculate (and subtract) whole days
    var days = Math.floor(delta / 86400);
    // console.log( "day",delta)
    // calculate (and subtract) whole hours
    var hours = (Math.floor(delta / 3600) % 24);
    var minutes = Math.abs(endCDate.getTime() - startCDate.getTime()) / (1000 * 60) % 60;
    var seconds = Math.abs(endCDate.getTime() - startCDate.getTime()) / (1000) % 60;
    // console.log("hours", days, hours, Math.round(minutes), Math.round(seconds)) 
    if (days > 0) {
      if (days > 3) {
        return days + " " + "Days" + " " + "ago";
      } else {
        if (days > 2) {
          return this.datePipe.transform(date, 'MMMM d, y')
        }
      }
    } else if (hours <= 24 && hours > 0) {
      return hours + " " + "Hours" + " " + "ago";
    } else if (minutes < 60 && minutes > 0) {
      return Math.round(minutes) + " " + "Minutes" + " " + "ago";
    } else if (seconds < 60) {
      return Math.round(seconds) + " " + "Seconds" + " " + "ago";
    }
  }

  notificationView(notify) {
    if (notify.app == 'sdms') {
      let url = this.sdmsURL + "?appmode=chit&chitId=" + notify.chitId + "&userId=" + this.profileDetails[0]['_id'];
      let data = { "key": "sdms", "url": url };
      this.openContentPage.emit(data);
    }
    if (notify.app == 'scs' && notify['link']) {
      let url = notify['link'];
      let data = { "key": "scs", "url": url };
      this.openContentPage.emit(data);
    }
  }
  webView: any = true;
  @HostListener('window:resize', ['$event']) onResize(event) {
    console.log(event.target.innerWidth, event.target.innerHeight);
    if (innerWidth < 1024) {
      this.webView = false;
    } else {
      if(this.isTouchDevice()){
        this.webView = false;
      }else{
        this.webView = true;
      }
     
    }
  }
}

@Component({
  selector: 'header-notes-popup',
  templateUrl: './header-notes-popup.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderContentDialog implements OnInit {
  dialog: any;

  constructor(private router: Router, public dialogRef: MatDialogRef<HeaderContentDialog>, @Inject(MAT_DIALOG_DATA) public message: any) {
    console.log("message=", this.message);
  }

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close(false);
  }

  continue() {
    this.dialogRef.close(true);
  }
}

// profile 

@Component({
  selector: './profile-popup',
  templateUrl: './profile-popup.html',
  styleUrls: ['./header.component.scss']
})
export class ProfilePopup {
  languageSelected: any = '';
  selectedLogo: boolean = true;
  selectedLogoList: boolean = false;
  pacificCheck: boolean = true;
  atlanticCheck: boolean = false;
  cellNumber: boolean = false;
  phoneNumber: boolean = false;
  cellNumberDefualt: boolean = true;
  phoneNumberDefualt: boolean = true;
  profileDetails: any = '';
  userLoginID: any = "";
  defaultUser = { "_id": "610d3dbfd6b4251d4cc9b220", "email": "default", "SCS": "Y", "SDMS": "Y", "MARTECH": "Y", "MARLANT": "Y", "MARPAC": "Y", "Last Name": "User", "First Name": "Default", "Rank E": "Civ", "Rank F": "Civ", "Org E": "Datifex, Inc.", "Org F": "Datifex, Inc.", "Role": null, "Cell": "(902) 439-4509", "Location": null, "Language": "E", "Admin": "Y", "hazard": "Y", "aed": "N", "first-aid": "Y", "spill-kits": "N", "evacuation-posters": "N", "kiosk": "Y", "laydown": "Y", "contractors": "N", "portable-generators": "N", "fork-lifts": "N", "cranes": "N", "FMF": "Y", "WFM": "Y" }

  // selectedlogo = "un-selected-log";
  @Input() loggedIn: any = false;
  broadcastSubs: Subscription;
  constructor(private router: Router, @Inject(MAT_DIALOG_DATA) public data: HeaderComponent, private communicationServ: CommunicationService, private cdr: ChangeDetectorRef, private translate: TranslateService, private dialog: MatDialog, private languageChange: LanguageService, public configService: ConfigService, private broadcastService: BroadcastService, private authService: MsalService, private http: HttpClient) {
    let Lan;
    if (data.profileDetails.length > 0) {
      Lan = data.profileDetails[0];
      this.profileDetails = data.profileDetails[0];
    } else {
      Lan = this.defaultUser
      this.profileDetails.this.defaultUser;
    }  
    this.userLoginID = data.accountEmail;

    console.log("Profile data recives", this.profileDetails);  
    if (Lan) {
      if (Lan.Language === 'E') {
        this.languageSelected = 'English';
      } else {
        this.languageSelected = 'French';
      }
    }
    // this.languageSelected = this.profileDetails.Language;
    if (this.loggedIn) {
      this.translate.addLangs(['en', 'fr']);
      this.translate.setDefaultLang('en');
      this.translate.use('en');
    }
    this.broadcastSubs = this.communicationServ.getInstance()
      .subscribe((data: any) => {
        if (data.src === "main") {
          if (data.event === 'dologin') {
            this.login();
          }
        }
      })
  }

  login() {
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

    if (isIE) {
      this.authService.loginRedirect();
    } else {
      this.authService.loginPopup();
    }
  }

  logout() {
    this.authService.logout();
  }

  profileChangeSection() {
    this.selectedLogoList = true;
  }
  cellNumberChange() {
    this.cellNumber = true;
    this.cellNumberDefualt = false;
  }
  phoneNumberChange() {
    this.phoneNumber = true;
    this.phoneNumberDefualt = false;
  }
  cancelChange() {
    this.phoneNumber = false;
    this.cellNumber = false;
    this.phoneNumberDefualt = true;
    this.cellNumberDefualt = true;
  }
  selectAtlanticLogo() {
    // this.atlanticCheck = true;
    this.atlanticCheck = !this.atlanticCheck;
  }
  selectPacificLogo() {
    this.pacificCheck = !this.pacificCheck;
  }
  // nxtLanguage: any = 'English';
  onChangeLanguage(langName) {
    // this.languageSelected = this.profileDetails.Language;
    // let langKey;
    if (langName == 'English') {
      this.languageChange.changeLanguage('fr');
      this.translate.use('fr');
      this.languageSelected = 'French';
      // this.nxtLanguage = 'French';
    }
    if (langName == 'French') {
      this.languageChange.changeLanguage('en');
      this.translate.use('en');
      // this.nxtLanguage = 'English';
      this.languageSelected = 'English';
    }
  }
}
function logout(): (error: any) => void {
  throw new Error('Function not implemented.');
}

