import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../services/user.service';

export interface DialogData {
  data: string;
  error: any;
  type: any;
}

@Component({
  selector: 'app-qr-scanner',
  templateUrl: './qr-scanner.component.html',
  styleUrls: ['./qr-scanner.component.scss']
})
export class QrScannerComponent implements OnInit {

  constructor(private userService: UserService, public dialog: MatDialog) { }

  Locations = ["Gate In", "Gate Out", "C1", "C2", "C3", "C4"];
  selectedLocation = "Gate In";
  ngOnInit() {
  }

  /* * * * *
  * check Json or Not
  * * * * * */
  hasJsonStructure(str) {
    if (typeof str !== 'string') return false;
    try {
      const result = JSON.parse(str);
      const type = Object.prototype.toString.call(result);
      return type === '[object Object]'
        || type === '[object Array]';
    } catch (err) {
      return false;
    }
  }

  public onSuccess(data, action) {
    if (data) {
      action.stop();
      let date = new Date();
      var isJson = this.hasJsonStructure(data)
      if (isJson) {
        let obj = JSON.parse(data);
        if (obj.visitorID) {
          let location = this.selectedLocation.split(" ").join("").toLocaleLowerCase();
          obj['lastScanDateTime'] = date;
          obj['lastScanLocation'] = this.selectedLocation;
          if (location == 'gatein') {
            obj['signin'] = date;
            this.visitorSignIn(obj);
          } else if (location == 'gateout') {
            obj['signout'] = date;
            this.visitorSignOut(obj);
          } else {
            this.visitorScan(obj);
          }
          this.openDialog("Qr-code Scanned Successfully !!");
          console.log("onSuccess ", obj)
        } else {
          this.openDialog("Invaild QR-code !!");
        }
      } else {
        this.openDialog("Invaild Data !!");
      }
    }
  }

  public onError(e): void {
    alert(e);
  }

  visitorSignIn(obj) {
    if (obj) {
      this.userService.visitorSignin(obj).subscribe(res => {
        console.log("visitorSignIn Sucess !!")
      })
    }
  }

  visitorSignOut(obj) {
    if (obj) {
      this.userService.visitorSignout(obj).subscribe(res => {
        console.log("visitorSignOut Sucess !!")
      })
    }
  }

  visitorScan(obj) {
    if (obj) {
      this.userService.visitorScan(obj).subscribe(res => {
        console.log("visitorScan Sucess !!")
      })
    }
  }


  /* * *
  * method for openDialog comp
  * * */
  openDialog(e): void {
    let width = '285px';
    let type = 'error';

    if (e == 'chartmodel') {
      type = 'chartmodel';
      width = '750px'
    }

    const dialogRef = this.dialog.open(PopupComponent, {
      panelClass: 'full-width-dialog',
      width: width,
      data: { value: '', error: e, type: type },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}



@Component({
  selector: 'popup-model',
  templateUrl: 'popup-model.html',
})
export class PopupComponent {
  constructor(
    public dialogRef: MatDialogRef<PopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  closeClick(): void {
    this.dialogRef.close();
  }

}