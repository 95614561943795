import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChitService {
  accessToken: any;
  refershToken: any;

  // local
  // baseUrl = 'http://localhost:3000/';
  // url: any = 'http://localhost:3000/chitmaster/';  
  // urlOOD: any = 'http://localhost:3000/chitmaster_ood/'; 
  // urlVessel: any = 'http://localhost:3000/chitmaster_vessel/';
  // urlTagout: any = 'http://localhost:3000/tagoutmaster/';
  // urlRole: any = 'http://localhost:3000/tagoutRoles/';

  // live
  baseUrl = 'https://nodesdms.azurewebsites.net/'
  url: any = 'https://nodesdms.azurewebsites.net/chitmaster/';
  urlOOD: any = 'https://nodesdms.azurewebsites.net/chitmaster_ood/';
  urlVessel: any = 'https://nodesdms.azurewebsites.net/chitmaster_vessel/';
  urlTagout: any = 'https://nodesdms.azurewebsites.net/tagoutmaster/';
  urlRole: any = 'https://nodesdms.azurewebsites.net/tagoutRoles/';

  private subject = new Subject<any>();

  constructor(private http: HttpClient) { }

  // user
  getUserData(): Observable<any> {
    return this.http.get(this.baseUrl + 'authaccess/');
  }

  // Supervisor Services
  getSupervisorData(): Observable<any> {
    return this.http.get(this.url)
  }
  getSupervisorDataList(id: string): Observable<any> {
    return this.http.get(this.url + id)
  }
  deleteSupervisor(id: string): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    // const body=JSON.stringify(data);
    return this.http.delete(this.url + id, { 'headers': headers })
  }
  addSupervisor(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.url, body, { 'headers': headers })
  }
  updateSupervisor(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.url + id, body, { 'headers': headers })
  }

  // Ood Schedule services

  getOodData(): Observable<any> {
    return this.http.get(this.urlOOD)
  }
  getOodDataList(id: string): Observable<any> {
    return this.http.get(this.urlOOD + id)
  }
  deleteOod(id: string): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    // const body=JSON.stringify(data);
    return this.http.delete(this.urlOOD + id, { 'headers': headers })
  }
  addOod(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.urlOOD, body, { 'headers': headers })
  }
  updateOod(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.urlOOD + id, body, { 'headers': headers })
  }

  // vessel services

  getVesselData(): Observable<any> {
    return this.http.get(this.urlVessel)
  }
  getVesselDataList(id: string): Observable<any> {
    return this.http.get(this.urlVessel + id)
  }
  deleteVessel(id: string): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    // const body=JSON.stringify(data);
    return this.http.delete(this.urlVessel + id, { 'headers': headers })
  }
  addVessel(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.urlVessel, body, { 'headers': headers })
  }
  updateVessel(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.urlVessel + id, body, { 'headers': headers })
  }

  // Tagout services

  getTagoutData(): Observable<any> {
    return this.http.get(this.urlTagout)
  }
  getTagoutDataList(id: string): Observable<any> {
    return this.http.get(this.urlTagout + id)
  }
  deleteTagout(id: string): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    // const body=JSON.stringify(data);
    return this.http.delete(this.urlTagout + id, { 'headers': headers })
  }
  addTagout(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.urlTagout, body, { 'headers': headers })
  }
  updateTagout(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.urlTagout + id, body, { 'headers': headers })
  }

  // Tagout services
  getRoles(): Observable<any> {
    return this.http.get(this.urlRole);
  }
  getUserRoles(id: string): Observable<any> {
    return this.http.get(this.urlRole + id);
  } 
  getRole(id: string): Observable<any> {
    return this.http.get(this.urlRole + 'role/' + id);
  } 
  addRole(data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(this.urlRole, body, { 'headers': headers });
  }
  updateRole(id: string, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.put(this.urlRole + id, body, { 'headers': headers });
  }
  deleteRole(id: string): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    // const body=JSON.stringify(data);
    return this.http.delete(this.urlRole + id, { 'headers': headers });
  }

  // get profile data
  sendProfileData(message: string) {
    this.subject.next({ text: message });
  }
  getProfileData(): Observable<any> {
    return this.subject.asObservable();
  }

  //  Reload data
  sendReload(reload: string) {
    this.subject.next({ text: reload });
  }
  getReload(): Observable<any> {
    return this.subject.asObservable();
  }
  pullJsonData(url, data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.pullData(this.baseUrl + url, data).subscribe(data => {
        resolve(data)
      },
        error => reject(error)
      );
    })
  }

  /* * *
  * post api call
  * * */
  pullData(url, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(url, body, { 'headers': headers })
  }

  /* * *
  * method for get Json data from server
  * * */
  // getData(url, data, cbck, type = 'POST') {
  //   let self = this;
  //   if (type == 'POST') {
  //     return new Promise((resolve, reject) => {
  //       self.pullData(url, data).subscribe(data => {
  //         cbck(data)
  //       },
  //         error => reject(error)
  //       );
  //     })
  //   } else {
  //     return new Promise((resolve, reject) => {
  //       self.getApiCall(url).subscribe(data => {
  //         return data;
  //       },
  //         error => reject(error)
  //       );
  //     })
  //   }
  // }
}
